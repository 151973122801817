import * as drawingUtils from "@mediapipe/drawing_utils";
import { NormalizedRect } from "@mediapipe/face_detection";
import { Results, FaceDetectionResults } from "./types";
import { isRectangleWithinBounds, centerBox } from "./rect";

const style: drawingUtils.DrawingOptions = {
  color: "#45B38B",
  lineWidth: 5,
};

const invalidStyle: drawingUtils.DrawingOptions = {
  color: "#fff",
  lineWidth: 5,
};

const faceRectStyle: drawingUtils.DrawingOptions = {
  ...style,
  fillColor: "#00000000",
};

const boundsStyle: drawingUtils.DrawingOptions = {
  ...style,
  fillColor: "#00000000",
};

const invalidBoundsStyle: drawingUtils.DrawingOptions = {
  ...invalidStyle,
  fillColor: "#00000000",
};

const drawFaceDetection = (
  cC: CanvasRenderingContext2D,
  results: FaceDetectionResults
) => {
  for (const detection in results.detections) {
    drawingUtils.drawRectangle(cC, centerBox, faceRectStyle);
    drawBoundingBox(cC, centerBox, results.detections[detection].boundingBox);
  }
};

export const drawBoundingBox = (
  cC: CanvasRenderingContext2D,
  boundingBox: NormalizedRect,
  rect: NormalizedRect
) => {
  if (rect && isRectangleWithinBounds(rect, boundingBox)) {
    drawingUtils.drawRectangle(cC, boundingBox, boundsStyle);
  } else {
    drawingUtils.drawRectangle(cC, boundingBox, invalidBoundsStyle);
  }
};

export const drawSolution = (
  cC: CanvasRenderingContext2D,
  results: Results
) => {
  drawFaceDetection(cC, results as FaceDetectionResults);
};
