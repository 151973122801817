function Stepper3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="384"
      height="32"
      fill="none"
      viewBox="0 0 384 32"
    >
      <rect width="32" height="32" fill="#45B38B" rx="16"></rect>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21.91 11.757a.881.881 0 010 1.246l-7.671 7.737-4.147-4.213a.88.88 0 111.246-1.246l2.9 2.9 6.425-6.424a.881.881 0 011.246 0z"
        clipRule="evenodd"
      ></path>
      <path fill="#000" d="M32 15H176V17H32z"></path>
      <rect width="32" height="32" x="176" fill="#45B38B" rx="16"></rect>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M197.909 11.757a.881.881 0 010 1.246l-7.67 7.737-4.147-4.213a.88.88 0 111.246-1.246l2.901 2.9 6.424-6.424a.881.881 0 011.246 0z"
        clipRule="evenodd"
      ></path>
      <path fill="#000" d="M208 15H352V17H208z"></path>
      <rect width="32" height="32" x="352" fill="#45B38B" rx="16"></rect>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M373.909 11.757a.881.881 0 010 1.246l-7.67 7.737-4.147-4.213a.88.88 0 111.246-1.246l2.901 2.9 6.424-6.424a.881.881 0 011.246 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Stepper3;
