import React from "react";

type FrameLoopProps = {
  time: number;
  deltaTime: number;
  fps: number;
};

type FrameLoopReturnProps = {
  paused: boolean;
  start: () => void;
  stop: () => void;
};

const SPEED = 1;

const useFrameLoop = (
  callback: ({ time, deltaTime, fps }: FrameLoopProps) => void
): FrameLoopReturnProps => {
  const requestID = React.useRef<undefined | number>();
  const previousTime = React.useRef<number>();
  const startTime = React.useRef<number>();
  const paused = React.useRef<boolean>(false);
  const [isReady, setIsReady] = React.useState<boolean>(false);

  const loop = React.useCallback(
    (time: number) => {
      if (startTime.current === undefined) startTime.current = time;

      if (previousTime.current !== undefined) {
        const deltaTime = time - previousTime.current;
        const secondsPassed = deltaTime / 1000;
        const fps = Math.round(1 / secondsPassed);
        callback({ time: (time - startTime.current) * SPEED, deltaTime, fps });
      }

      previousTime.current = time;

      if (isReady && !paused.current)
        requestID.current = requestAnimationFrame(loop);
    },
    [callback, isReady]
  );

  const start = () => {
    requestID.current && cancelAnimationFrame(requestID.current);
    if (isReady && !paused.current) {
      paused.current = false;
      requestID.current = requestAnimationFrame(loop);
    }
  };

  const stop = () => {
    paused.current = true;
    requestID.current && cancelAnimationFrame(requestID.current);
  };

  React.useEffect(() => {
    setIsReady(true);
    return () => {
      setIsReady(false);
    };
  }, []);

  return { paused: paused.current, start, stop };
};

export default useFrameLoop;
