function CheckMarkRed() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#BA1A1A" rx="16"></rect>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M22.707 10.707a1 1 0 00-1.414-1.414L16 14.586l-5.293-5.293a1 1 0 00-1.414 1.414L14.586 16l-5.293 5.293a1 1 0 101.414 1.414L16 17.414l5.293 5.293a1 1 0 001.414-1.414L17.414 16l5.293-5.293z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CheckMarkRed;
