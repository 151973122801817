import { Title, Paragraph, Gap, Image, Headline } from "components";
import {
  TestInstruction1_1_child,
  TestInstruction2_3_child,
  TestInstruction2_4_child,
  SpacebarClickPhoto,
  TestInstruction3_child_circle,
  TestInstruction3_child_circle_crossed,
  TestInstruction4_1_child,
} from "assets/images";

const gapSize = 30;

const ChildStep0 = () => {
  return (
    <>
      <Title text="Test instructions" />
      <Paragraph align="left">
        Now that you are set up and ready to take the test, let's have a look
        into how the test works.
      </Paragraph>
    </>
  );
};

const ChildStep1 = () => {
  return (
    <>
      <Title text="Test Instructions" size="large" />
      <Paragraph>Step 1/4</Paragraph>
      <Headline text="What the test is about" size="small" />
      <Paragraph align="left" type="bulletList">
        This is a task to measure the test taker's attention, impulse control
        and their activity levels.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        It will take 15 minutes.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        During the test the camera will track movements but it will not capture
        images of the test taker.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        The following instructions will explain what to do in the test.
      </Paragraph>
      <Image src={TestInstruction1_1_child} />
      <Gap size={gapSize} />
    </>
  );
};

const ChildStep2 = () => {
  return (
    <>
      <Title text="Test Instructions" size="large" />
      <Paragraph>Step 2/4</Paragraph>
      <Headline text="How it works" size="medium" />
      <Paragraph align="left">
        Let's look more in detail what you'll see and what you're supposed to
        do.
      </Paragraph>
      <Headline text="What you'll see" size="small" />
      <Paragraph align="left">
        These symbols will appear individually on the screen during the test: a
        grey circle or a grey circle with a cross.
      </Paragraph>
      <Image src={TestInstruction3_child_circle} />
      <Gap vertical size={gapSize} />
      <Image src={TestInstruction3_child_circle_crossed} />
      <Gap vertical size={gapSize} />
      <Headline text="What you'll need to do" size="small" />
      <Paragraph align="left" type="bulletList">
        Your task is to press when you see a grey circle.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Press as fast as you can and try to get as many right as possible.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Symbols are shown fast. Don’t worry, you are still allowed to press
        after the symbol disappears.
      </Paragraph>
      <Image src={TestInstruction2_3_child} />
      <Gap size={gapSize} vertical />
      <Headline text="What not to do" size="small" />
      <Paragraph align="left">
        Do not press when you see a grey circle with a cross
      </Paragraph>
      <Image src={TestInstruction2_4_child} />
      <Gap size={gapSize} vertical />
      <Headline text="How you'll need to press" size="small" />
      <Paragraph align="left">
        Only use the hand you write or color with when pressing the space bar.
      </Paragraph>
      <Image src={SpacebarClickPhoto} />
      <Gap vertical size={gapSize} />
    </>
  );
};

const ChildStep3 = () => {
  return (
    <>
      <Title text="Test Instructions" size="large" />
      <Paragraph>Step 3/4</Paragraph>
      <Headline text="Example" size="medium" />
      <Paragraph align="left">
        Let's look at an example of how the symbols will appear on the screen
        and what you have to do.
      </Paragraph>
      <Image src={TestInstruction2_4_child} />
      <Gap vertical size={gapSize} />
      <Image src={TestInstruction2_3_child} />
      <Gap vertical size={gapSize} />
      <Image src={TestInstruction2_3_child} />
      <Gap vertical size={gapSize} />
      <Image src={TestInstruction2_4_child} />
      <Gap vertical size={gapSize} />
      <Image src={TestInstruction2_3_child} />
      <Gap vertical size={gapSize} />
      <Image src={TestInstruction2_4_child} />
      <Gap vertical size={gapSize} />
      <Image src={TestInstruction2_3_child} />
      <Gap vertical size={gapSize} />
    </>
  );
};

const ChildStep4 = () => {
  return (
    <>
      <Title text="Test Instructions" size="large" />
      <Paragraph>Step 4/4</Paragraph>
      <Headline text="Final tips" size="medium" />
      <Paragraph align="left" type="bulletList">
        It's ok if you make a mistake, everyone makes them. Just try to do your
        best.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        You might get a little tired or bored but try to stay focused and quiet
        during the whole test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        The test will stop by itself after 15 minutes.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        You will now do a 1-minute Ability Test to make sure that you have
        understood the instructions.
      </Paragraph>
      <Image src={TestInstruction4_1_child} />
      <Gap size={gapSize} />
    </>
  );
};

export { ChildStep0, ChildStep1, ChildStep2, ChildStep3, ChildStep4 };
