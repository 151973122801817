import { useCountDown } from "hooks";
import { Results } from "utils/cam/types";
import { startCamera, stopCamera } from "utils/cam";

import React from "react";

type CamProps = {
  faceTimeoutValue: number;
  faceTimedoutCallback: () => void;
  skipCompatibilityCheck?: boolean;
  faceTrackingResultCallback?: (result: Results) => void;
  storeStartSubscribe?: (listener: () => void) => void;
  shouldShowVideo?: boolean;
};

const Cam = ({
  faceTimeoutValue,
  faceTimedoutCallback,
  skipCompatibilityCheck,
  shouldShowVideo,
  storeStartSubscribe,
  faceTrackingResultCallback,
}: CamProps) => {
  const canvasRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const timeRef = React.useRef<number>(faceTimeoutValue);

  const { startTimer, resetTimer, stopTimer } = useCountDown({
    duration: faceTimeoutValue,
    stopOnReset: false,
    callback: (timer) => {
      if (timer !== timeRef.current) {
        timeRef.current = timer;
        if (timer <= 0) {
          faceTimedoutCallback();
        }
      }
    },
  });

  const onResultsCallback = React.useCallback(
    (result: Results) => {
      if (result.withinBounds) {
        resetTimer();
      } else if (!result.withinBounds) {
        startTimer();
      }
      if (faceTrackingResultCallback) {
        faceTrackingResultCallback(result);
      }
    },
    [faceTrackingResultCallback, resetTimer, startTimer]
  );

  const startCam = React.useCallback(() => {
    if (canvasRef.current && videoRef.current)
      startCamera({
        canvasElementReact: canvasRef.current,
        videoElementReact: videoRef.current,
        onResultsCallback: onResultsCallback,
        size: !shouldShowVideo ? { w: 640, h: 480 } : undefined,
        skipCompatibilityCheck: skipCompatibilityCheck,
        shouldShowVideo,
      });
  }, [onResultsCallback, shouldShowVideo, skipCompatibilityCheck]);

  React.useEffect(() => {
    startCam();
    storeStartSubscribe && storeStartSubscribe(startTimer);
    return () => {
      stopCamera();
      stopTimer();
    };
  }, [startCam, startTimer, stopTimer, storeStartSubscribe]);

  React.useEffect(() => {
    return () => {
      stopCamera();
    };
  }, []);

  return (
    <>
      <canvas ref={canvasRef} className={shouldShowVideo ? "" : "hidden"} />
      <video ref={videoRef} className="hidden" />
    </>
  );
};

export default React.memo(Cam);
