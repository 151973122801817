import React from "react";
import Grid from "components/Grid";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDataProvider } from "contexts";
import { routes } from "utils/routingTable";
import { MainBackButton } from "./components/BackButton";

const DebugNavigator = () => {
  const [show, setShow] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="fixed z-20 left-0 h-auto w-50 text-xs rounded-br-md bg-primary-40 bg-opacity-50 flex flex-col pt-1">
      <div className="text-body-small flex mb-1">
        <button
          tabIndex={-1}
          className="p-1 px-2 bg-white rounded ml-2 my-2 "
          id="back-button"
          onClick={() => navigate(-1)}
        >
          Previous
        </button>
        <button
          tabIndex={-1}
          className="p-1 px-2 bg-white rounded ml-4 my-2"
          id="next-button"
          onClick={() => navigate(+1)}
        >
          Next
        </button>
        <button
          tabIndex={-1}
          className="p-1 px-2 bg-white rounded ml-4 my-2 mr-2"
          id="toggle-menu-button"
          onClick={() => setShow(!show)}
        >
          Show/Hide
        </button>
      </div>
      {show &&
        routes.map((route: any, i: number) => {
          const activeClasses = "underline font-bold";
          const gotComponentClasses = "text-[#48f9be]";
          const noComponentClasses = "text-white";
          const linkClasses =
            "py-1 px-2 cursor-pointer ml-2 mr-2 hover:ml-1 transition-all";
          return (
            <div
              key={i}
              className={[
                linkClasses,
                route.element ? gotComponentClasses : noComponentClasses,
                "/" + route.path === pathname && activeClasses,
              ].join(" ")}
              onClick={() => navigate(route.path ? "/" + route.path : "/")}
            >
              {route.path || "VoucherPage"}
            </div>
          );
        })}
    </div>
  );
};

function App() {
  const { debugMode } = useDataProvider();

  return (
    <div className="h-screen">
      {debugMode && <DebugNavigator />}
      <MainBackButton />
      <Grid>
        <Outlet />
      </Grid>
    </div>
  );
}

export default App;
