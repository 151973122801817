// Description: Default values for the Pipefeed component

import { VideoSize, Options } from "./types";
import { SolutionChoices } from "./enums";

export const defaultOptions: Options = {
  model: "short",
  modelComplexity: 1,
  smoothLandmarks: true,
  enableSegmentation: false,
  refineFaceLandmarks: true,
  minDetectionConfidence: 0.5,
  minTrackingConfidence: 0.5,
  selfieMode: true,
};

export const defaultVideoSize: VideoSize = {
  width: 800,
  height: 600,
};

export const defaultSolutionChoice = SolutionChoices.faceDetection;

export const videoStyle: React.CSSProperties = {
  position: "absolute",
  width: "100%",
  height: "100%",
};

export const defaultCompatibilityWhitelist = [
  { os: "Windows", client: "Chrome" },
  { os: "Windows", client: "Firefox" },
  { os: "Mac", client: "Chrome" },
  { os: "Mac", client: "Firefox" },
];
