// ---------------------------- Schema enums ----------------------------

export enum SEX {
  F = "F",
  M = "M",
}
export enum BUSINESS_TYPE {
  CLASSIC = "Classic",
  CONSUMER = "Consumer",
  PRO = "Pro",
}

export enum RACE {
  ASIAN = "ASIAN",
  BLACK = "BLACK",
  MENA_AND_ARAB = "MENA_AND_ARAB",
  NATIVE_HAWAIIAN = "NATIVE_HAWAIIAN",
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = "AMERICAN_INDIAN_OR_ALASKA_NATIVE",
  OTHER = "OTHER",
  PREFER_NOT_TO_SAY = "PREFER_NOT_TO_SAY",
  WHITE = "WHITE",
}

export enum ETHNICITY {
  HISPANIC = "HISPANIC",
  NOT_HISPANIC = "NOT_HISPANIC",
  PREFER_NOT_TO_SAY = "PREFER_NOT_TO_SAY",
}

export enum EYE_COLOR {
  BROWN = "Brown",
  AMBER = "Amber",
  HAZEL = "Hazel",
  GREEN = "Green",
  BLUE = "Blue",
  GREY = "Grey",
}

export enum VISUAL_AID {
  GLASSES = "GLASSES",
  CONTACT_LENSES = "CONTACT_LENSES",
  OTHER = "OTHER",
}

// ---------------------------- IndexedDB enums ----------------------------

export enum COLLECTION_NAMES {
  TEST_DATA_RECORD_FILES = "testDataRecordFiles",
}
