import { ReactElement } from "react";

type GapProps = {
  vertical?: boolean;
  horizontal?: boolean;
  size: number;
};

const Gap: React.FC<GapProps> = ({
  vertical,
  horizontal,
  size = 0,
}: GapProps): ReactElement => {
  const classes = "w-full";
  return (
    <span
      className={classes}
      style={vertical ? { height: size } : { width: size }}
    ></span>
  );
};

export default Gap;
