import { isClickTarget } from "./isClickTarget";
import { StimuliTiming } from "contexts/indexedDBContext/types";

export type EvaluationData = {
  nStimulis: number;
  nValidClickWindows: number;
  nValidClicks: number;
  nCommissionErrors: number;
  nOmissionErrors: number;
  nOmissionErrorPercentage: number;
  nCommissionErrorPercentage: number;
};

type StimuliTimingsWithMatchingClicks = StimuliTiming & {
  matchingClicks?: number[];
  consecutiveStimuli?: number;
};

// This functions run in 0.019ms for a full abilityTest.
export const evaluateClickData = ({
  clickData,
  stimuliData,
  isChild,
}: {
  clickData: number[];
  stimuliData: StimuliTiming[];
  isChild: boolean;
}) => {
  let nStimulis = stimuliData.length;
  let nValidClickWindows = stimuliData.filter((obj) =>
    isClickTarget(obj.stimuliId || 0)
  ).length;
  let nValidClicks = 0;
  let nOmissionErrors = 0;
  let nCommissionErrors = 0;

  const stimuliDataArr = [...stimuliData];
  const clickDataArr = [...clickData];

  let consecutiveCounter = 0;

  const result: StimuliTimingsWithMatchingClicks[] = stimuliDataArr.map(
    (stimuli: StimuliTiming, index) => {
      const updatedStimuliData = { ...stimuli };
      const timestamp = stimuli.timestamp;
      if (!timestamp) {
        console.warn("Can not find timestamp so can not evaluate data");
        return updatedStimuliData;
      }

      const nextShowFrame = stimuliDataArr[index + 1]?.timestamp;
      const end = nextShowFrame ? nextShowFrame - 1 : timestamp + 1999;

      let matchingClicks: number[] = [];

      for (let i = clickDataArr.length - 1; i >= 0; i = i - 1) {
        if (clickDataArr[i] > timestamp && clickDataArr[i] < end) {
          const item: number[] = clickDataArr.splice(i, 1);
          matchingClicks.push(...item);
        }
      }

      if (isClickTarget(stimuli.stimuliId || 0)) {
        consecutiveCounter++;
      } else {
        consecutiveCounter = 0;
      }

      return {
        ...updatedStimuliData,
        matchingClicks: matchingClicks,
        consecutiveStimuli: consecutiveCounter,
      };
    }
  );

  let consecutiveMatchingClicks = 0;
  let foundConsecutiveClick = false;

  result.forEach((event) => {
    const clickTarget = isClickTarget(event.stimuliId);
    const consecutiveClickTarget =
      event.consecutiveStimuli && event.consecutiveStimuli >= 1;
    // User should click
    if (clickTarget && event.matchingClicks) {
      // User Clicked when it should
      if (event.matchingClicks.length === 1) {
        nValidClicks++;
        if (consecutiveClickTarget) {
          consecutiveMatchingClicks++;
        }

        if (consecutiveMatchingClicks >= 2) {
          foundConsecutiveClick = true;
        }
      }

      // User didnt click but should have
      if (event.matchingClicks.length === 0) {
        consecutiveMatchingClicks = 0;
        nOmissionErrors++;
      }

      // User clicked more then once on a click it should have clicked on
      if (event.matchingClicks.length > 1) {
        nCommissionErrors = nCommissionErrors + event.matchingClicks.length - 1;
      }
    }

    // User should not click
    if (!clickTarget && event.matchingClicks) {
      consecutiveMatchingClicks = 0;
      if (event.matchingClicks.length > 0) {
        nCommissionErrors = nCommissionErrors + event.matchingClicks.length;
      }
    }
  });

  let nOmissionErrorPercentage = 100 * (nOmissionErrors / nValidClickWindows);
  let nOmissionErrorPercentageRounded =
    Math.round((nOmissionErrorPercentage + Number.EPSILON) * 10) / 10;
  let nCommissionErrorPercentage =
    100 * (nCommissionErrors / (nStimulis - nValidClickWindows));
  let nCommissionErrorPercentageRounded =
    Math.round((nCommissionErrorPercentage + Number.EPSILON) * 10) / 10;

  const passedQualityControl = () => {
    if (isChild) {
      return nOmissionErrorPercentage < 30 && nCommissionErrorPercentage < 30;
    } else {
      return (
        nOmissionErrorPercentageRounded < 30 &&
        nCommissionErrorPercentageRounded < 30 &&
        foundConsecutiveClick
      );
    }
  };

  const passed = passedQualityControl();

  const data = {
    passed,
    foundConsecutiveClick,
    nStimulis,
    nValidClickWindows,
    nValidClicks,
    nCommissionErrors,
    nOmissionErrors,
    nOmissionErrorPercentage: nOmissionErrorPercentageRounded,
    nCommissionErrorPercentage: nCommissionErrorPercentageRounded,
  };

  return data;
};
