function ArrowBack({ size }: { size?: number }) {
  return (
    <svg
      width={size ? size.toString() : "16"}
      height={size ? size.toString() : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z"
        fill="#1A1C1E"
      />
    </svg>
  );
}

export default ArrowBack;
