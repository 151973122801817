import { Title, Paragraph, Gap, Image, Headline } from "components";
import { CheckMarkGreen, CheckMarkRed } from "assets/svgs";
import {
  RoomInstruction1,
  RoomInstruction2,
  RoomInstruction3,
  RoomInstruction4,
  RoomInstruction5,
  RoomInstruction6,
  RoomInstruction7,
  RoomInstruction8,
} from "assets/images";

const gapSize = 30;

const Step0 = () => {
  return (
    <>
      <Title text="Getting ready for the test" />
      <Paragraph align="left">
        In the following steps you will be given information that helps you
        prepare for the test. Please read them and follow through, to make sure
        you are as prepared as possible.
      </Paragraph>
    </>
  );
};

const Step1 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 1/8</Paragraph>
      <Headline text="The room" size="medium" />
      <Paragraph align="left" type="bulletList">
        Find a quiet room free from disruption for the entire duration of the
        test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        If possible, ask people close to the room to remain quiet and not to
        disturb you during the test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Turn off other devices, such as phones, smart watches, tablets, tv and
        radios.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Please remove any devices indicating time, such as watches, timers or
        clocks.
      </Paragraph>
      <Image src={RoomInstruction1} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step2 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 2/8</Paragraph>
      <Headline text="The correct lighting" size="medium" />
      <Paragraph align="left" type="bulletList">
        Avoid direct sunlight by closing blinds and curtains.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Turn off very bright lights, normal ceiling lights are allowed.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Avoid having windows, mirrors or other objects that produce bright light
        behind you.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Adjust lighting so it appears even on your face.
      </Paragraph>
      <Image src={RoomInstruction2} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step3 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 3/8</Paragraph>
      <Headline text="The table and chair" size="medium" />
      <Paragraph align="left" type="bulletList">
        Make sure to use a stable table.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Clear the table from visual distractions such as office clutter,
        magazines, books etc.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Use a comfortable chair with firm backrest, without any wheels and
        armrest. The chair should neither swivel.
      </Paragraph>
      <Image src={RoomInstruction3} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step4 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 4/8</Paragraph>
      <Headline text="Your device" size="medium" />
      <Paragraph align="left" type="bulletList">
        Make sure your device is connected to a power outlet.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Disconnect any external monitors.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Close all other browser windows and tabs. Close all other applications.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Avoid using screen filters such as night shift as it may impact the
        colors.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Make sure you have a comfortable screen brightness.
      </Paragraph>
      <Image src={RoomInstruction4} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step5 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 5/8</Paragraph>
      <Headline text="Prepare" size="medium" />
      <Paragraph align="left" type="bulletList">
        It is not allowed to chew gum, use tobacco products, smoke, eat and
        drink during the test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Make sure your face remains fully visible and uncovered during the test.
        Don’t wear headwear such as hats, caps or scarves.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Regular glasses can be worn.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        There are no breaks during the test, so ensure you feel ready before you
        start.
      </Paragraph>

      <Image src={RoomInstruction5} />
      <Gap size={gapSize} vertical />
    </>
  );
};
const Step6 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 6/8</Paragraph>
      <Headline text="Seating" size="medium" />
      <Paragraph align="left" type="bulletList">
        Make sure you are seated comfortably and upright.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Make sure you steadily place your feet on the floor.
      </Paragraph>
      <Image src={RoomInstruction6} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step7 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 7/8</Paragraph>
      <Headline text="Device placement" size="medium" />
      <Paragraph align="left" type="bulletList">
        Place the device on the table so your face is at arm’s length distance
        (around 60 cm/24 inches) from the screen.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Remain seated upright, avoid leaning forward or backwards during the
        test.
      </Paragraph>
      <Image src={RoomInstruction7} />
      <div className="flex w-full mt-6">
        <div className="w-[56%] flex justify-center">
          <CheckMarkGreen />
        </div>
        <div className="flex justify-center w-[44%]">
          <CheckMarkRed />
        </div>
      </div>
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step8 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 8/8</Paragraph>
      <Headline text="Observers" size="medium" />
      <Paragraph align="left">
        If the test is performed at home we advise that any one under the age of
        18 years have an observer present. Observation in a clinic setting is at
        the discretion of each provider.
      </Paragraph>
      <Paragraph align="left">The observer should:</Paragraph>
      <Paragraph align="left" type="bulletList">
        Sit diagonally behind the test taker, outside of the camera’s and test
        taker’s field of vision.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Be able to see the side of the test taker’s face.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Remain quiet and avoid interaction with the test taker for the entire
        duration of the test. If interaction is unavoidable try to limit prompts
        to the beginning of the test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        If the test taker asks about how much time is left of the test,
        encourage the test taker to continue working without giving them any
        indication of how much time is remaining.
      </Paragraph>
      <Paragraph align="left">
        If the test is performed in clinic the Behavior Observation Form should
        be completed during the test.
      </Paragraph>
      <Image src={RoomInstruction8} />
      <Gap size={gapSize} vertical />
    </>
  );
};

export { Step0, Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8 };
