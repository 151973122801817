import React, { ReactElement } from "react";
import { ArrowBack } from "../../assets/svgs";

type BackButtonProps = {
  showButton?: boolean;
  onClick: () => void;
};

const BackButton: React.FC<BackButtonProps> = ({
  showButton = true,
  onClick,
}: BackButtonProps): ReactElement | null => {
  return showButton ? (
    <button
      data-testid="backButton"
      className="absolute top-12 left-9 p-2 w-10 text-gray-500 cursor-pointer flex flex-row space-x-2 items-center"
      onClick={onClick}
    >
      <div className="min-width-[16px]">
        <ArrowBack />
      </div>
      <span>Back</span>
    </button>
  ) : null;
};

export default BackButton;
