import { NormalizedRect } from "./types";

export const centerBox: NormalizedRect = {
  xCenter: 0.5,
  yCenter: 0.5,
  height: 0.75,
  width: 0.75,
  rotation: 0,
  rectId: 1,
};

export const isRectangleWithinBounds = (
  rect: NormalizedRect,
  bounds: NormalizedRect
): boolean => {
  // Calculate top left corner of rect
  const rectTopLeft = {
    x: rect.xCenter - rect.width * 0.5,
    y: rect.yCenter - rect.height * 0.5,
  };

  // Calculate top left corner of bounds
  const boundsTopLeft = {
    x: bounds.xCenter - bounds.width * 0.5,
    y: bounds.yCenter - bounds.height * 0.5,
  };

  // top left corner within bounds?
  if (rectTopLeft.x < boundsTopLeft.x || rectTopLeft.y < boundsTopLeft.y) {
    return false;
  }

  // bottom right corner within bounds?
  if (
    rectTopLeft.x + rect.width > boundsTopLeft.x + bounds.width ||
    rectTopLeft.y + rect.height > boundsTopLeft.y + bounds.height
  ) {
    return false;
  }
  return true;
};
