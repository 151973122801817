import { Title, Paragraph, Gap, Image, Headline } from "components";
import { CheckMarkGreen, CheckMarkRed } from "assets/svgs";
import {
  RoomInstruction1Child,
  RoomInstruction2Child,
  RoomInstruction3Child,
  RoomInstruction4,
  RoomInstruction5,
  RoomInstruction6Child,
  RoomInstruction7Child,
  RoomInstruction8Child,
} from "assets/images";

const gapSize = 30;

const Step0 = () => {
  return (
    <>
      <Title text="Getting ready for the test" />
      <Paragraph align="left">
        In the following steps you will be given information that helps you
        prepare for the test. Please read them and follow through, to make sure
        you are as prepared as possible.
      </Paragraph>
    </>
  );
};

const Step1 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 1/8</Paragraph>
      <Headline text="The room" size="medium" />
      <Paragraph align="left" type="bulletList">
        Find a quiet room free from disruption for the entire duration of the
        test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        If possible, ask people close to the room to remain quiet and not to
        disturb the test taker during the test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Turn off other devices, such as phones, smart watches, tablets, tv and
        radios.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Please remove any devices indicating time, such as watches, timers and
        clocks.
      </Paragraph>
      <Image src={RoomInstruction1Child} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step2 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 2/8</Paragraph>
      <Headline text="The correct lighting" size="medium" />
      <Paragraph align="left" type="bulletList">
        Avoid direct sunlight by closing blinds and curtains.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Turn off very bright lights, normal ceiling lights are allowed.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Avoid having windows, mirrors or other objects that produce bright light
        behind the test taker.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Adjust lightning so it appears even on the test takers face.
      </Paragraph>
      <Image src={RoomInstruction2Child} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step3 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 3/8</Paragraph>
      <Headline text="The table and chair" size="medium" />
      <Paragraph align="left" type="bulletList">
        Make sure to use a stable table.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Clear the area around the computer from visual distractions such as
        drawings, magazines, toys etc.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        The test taker should be seated on a stool without armrests or backrest.
        The stool should not have wheels nor swivel.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Avoid using any cushions or balancing aids on the chair.
      </Paragraph>
      <Image src={RoomInstruction3Child} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step4 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 4/8</Paragraph>
      <Headline text="Your device" size="medium" />
      <Paragraph align="left" type="bulletList">
        Make sure the device is connected to a power outlet.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Don’t use an external monitor.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Close all other browser windows and tabs. Close all other applications.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Avoid using filters such as night shift as it may impact the colors on
        the screen.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Make sure there is a comfortable screen brightness.
      </Paragraph>

      <Image src={RoomInstruction4} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step5 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 5/8</Paragraph>
      <Headline text="Prepare" size="medium" />
      <Paragraph align="left" type="bulletList">
        It is not allowed to chew gum, eat and drink during the test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Make sure the test taker’s face remains fully visible and uncovered
        during the test. Don’t wear headwear such as hats, caps or scarves.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Regular glasses can be worn.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Fidgeting tools such as stress balls and finger springs are not
        permitted during the test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Ensure small children used the bathroom prior to starting the test, as
        there are no breaks during the test.
      </Paragraph>
      <Image src={RoomInstruction5} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step6 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 6/8</Paragraph>
      <Headline text="Seating" size="medium" />
      <Paragraph align="left" type="bulletList">
        Make sure the test taker is seated comfortably and upright.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Make sure the test taker steadily places their feet on the floor or, if
        needed, on a footrest.
      </Paragraph>
      <Image src={RoomInstruction6Child} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step7 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 7/8</Paragraph>
      <Headline text="Device placement" size="medium" />
      <Paragraph align="left" type="bulletList">
        Place the device on the table so the test taker’s face is at arm’s
        length distance (around 60 cm/24 inches) from the screen.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        The test taker should remain seated upright, avoid leaning forward or
        backwards during the test.
      </Paragraph>
      <Image src={RoomInstruction7Child} />
      <div className="flex w-full mt-6">
        <div className="w-[56%] flex justify-center">
          <CheckMarkGreen />
        </div>
        <div className="flex justify-center w-[44%]">
          <CheckMarkRed />
        </div>
      </div>
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step8 = () => {
  return (
    <>
      <Title text="Getting ready for the test" size="large" />
      <Paragraph>Step 8/8</Paragraph>
      <Headline text="Observers" size="medium" />
      <Paragraph align="left">
        If the test is performed at home we advise that any one under the age of
        18 years have an observer present. Observation in a clinic setting is at
        the discretion of each provider.
      </Paragraph>
      <Paragraph align="left">The observer should:</Paragraph>
      <Paragraph align="left" type="bulletList">
        Sit diagonally behind the test taker, outside of the camera’s and test
        taker’s field of vision.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Be able to see the side of the test taker’s face.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Remain quiet and avoid interaction with the test taker for the entire
        duration of the test. If interaction is unavoidable try to limit prompts
        to the beginning of the test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        If the test taker asks about how much time is left of the test,
        encourage the test taker to continue working without giving them any
        indication of how much time is remaining.
      </Paragraph>
      <Paragraph align="left">
        If the test is performed in clinic the Behavior Observation Form should
        be completed during the test.
      </Paragraph>
      <Image src={RoomInstruction8Child} />
      <Gap size={gapSize} vertical />
    </>
  );
};

export { Step0, Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8 };
