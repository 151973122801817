type ImageProps = {
  src: string;
  className?: string;
};

const Image: React.FC<ImageProps> = ({ src, className }: ImageProps) => {
  return (
    <img src={src} alt="img" className={`w-full h-auto ${className ?? ""}`} />
  );
};

export default Image;
