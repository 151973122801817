import * as React from "react";
import useFrameLoop from "hooks/useFrameLoop";
import { Stats } from "./components/Stats";
import { StimuliImage } from "./components/StimuliImage";
import { StimuliTiming } from "contexts/indexedDBContext/types";

type TestProps = {
  children?: JSX.Element | JSX.Element[];
  isChild: boolean;
  images: { id: string; url: string }[];
  showStats?: boolean;
  nStimulisToShow?: number | undefined;
  testData: {
    stimuli: { id: string; url: string }[];
    events: { stimuliId: number; timestamp: number; visibleTime: number }[];
  };
  startRecording: () => void;
  testFinished: (arg0: boolean) => void;
  recordStimuliTimingData: ({
    stimuliId,
    expectedDisplayTime,
    timestamp,
  }: {
    stimuliId: number;
    expectedDisplayTime: number;
    timestamp: number;
  }) => void;
  recordQualityCheckError: (arg0: string) => void;
};

type TestPrematureStopReason =
  | "No face detected"
  | "No keyboardpresses"
  | "Closed Fullscreen mode";

const SECONDS_TO_START = 3;
const SECONDS_START_DELAY = 0;

const Test: React.FC<TestProps> = ({
  showStats,
  testData,
  nStimulisToShow,
  images,
  isChild,
  startRecording,
  recordStimuliTimingData,
  recordQualityCheckError,
  testFinished,
}) => {
  const testRef = React.useRef<HTMLDivElement>(null);
  const stimuliCalendarRef = React.useRef(
    typeof nStimulisToShow === "number"
      ? testData.events.splice(0, nStimulisToShow)
      : testData.events
  );
  const currentStimuliIndex = React.useRef(0);
  const [fps, setFps] = React.useState(0);
  const [time, setTime] = React.useState(0);
  const [started, setStarted] = React.useState<boolean>(false);
  const [testDone, setTestDone] = React.useState<boolean>(false);
  const [startTime, setStartTime] = React.useState<number>(0);
  const [deltaTime, setDeltaTime] = React.useState(0);
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [showStimuliId, setShowStimuliId] = React.useState<number | false>(
    false
  );
  const [duration, setDuration] = React.useState<number>(
    SECONDS_TO_START + SECONDS_START_DELAY
  );

  const stimuliTimings = React.useRef<StimuliTiming[]>([]);

  // Create a frameloop with requestAnimationFrame
  const { paused, start, stop } = useFrameLoop(({ time, deltaTime, fps }) => {
    setTime(Math.round(time));
    setDeltaTime(Math.round(deltaTime));
    setFps(fps);
    stimuliEngine(time);
  });

  const stopTest = React.useCallback(
    (reason: TestPrematureStopReason) => {
      stop();
      if (reason) {
        recordQualityCheckError(reason);
      }
    },
    [recordQualityCheckError, stop]
  );

  // Show stimuli and save timestamp
  const showStimuli = React.useCallback(
    ({
      stimuliId,
      expectedDisplayTime,
      timeStamp,
    }: {
      stimuliId: number;
      expectedDisplayTime: number;
      timeStamp: number;
    }) => {
      const arrayCopy = [...stimuliTimings.current];

      if (!arrayCopy[currentStimuliIndex.current]) {
        arrayCopy[currentStimuliIndex.current] = {
          timestamp: Math.round(timeStamp),
          expectedDisplayTime,
          stimuliId,
        };
        recordStimuliTimingData({
          stimuliId,
          expectedDisplayTime,
          timestamp: new Date().getTime(),
        });
      }

      stimuliTimings.current = arrayCopy;
      setShowStimuliId(stimuliId);
    },
    [recordStimuliTimingData]
  );

  const hideStimuli = React.useCallback(() => {
    setShowStimuliId(false);
  }, []);

  // Calculate what should be shown and when
  const stimuliEngine = React.useCallback(
    (timeStamp: number) => {
      const currentStimuli =
        stimuliCalendarRef.current[currentStimuliIndex.current];
      const nextStimuli =
        stimuliCalendarRef.current[currentStimuliIndex.current + 1];

      // If there is no more stimulis to show, and the last one is passed by 2000ms
      if (!nextStimuli && timeStamp - currentStimuli.timestamp > 2000) {
        setTestDone(true);
        stop();
        testFinished(isChild);
        return;
      }

      const currentStimuliShouldBeVisible =
        timeStamp >= currentStimuli.timestamp &&
        timeStamp <= currentStimuli.timestamp + currentStimuli.visibleTime;

      const currentStimuliIsOld =
        currentStimuli.timestamp + currentStimuli.visibleTime < timeStamp;

      if (currentStimuliShouldBeVisible) {
        showStimuli({
          stimuliId: currentStimuli.stimuliId,
          expectedDisplayTime: currentStimuli.timestamp,
          timeStamp,
        });
      } else {
        hideStimuli();
      }

      if (!nextStimuli) return;

      const nextStimuliIsOld =
        nextStimuli?.timestamp + nextStimuli?.visibleTime < timeStamp;

      const nextStimuliIsUpcoming = nextStimuli.timestamp > timeStamp;
      if (nextStimuliIsOld || (currentStimuliIsOld && nextStimuliIsUpcoming)) {
        currentStimuliIndex.current++;
      }
    },
    [hideStimuli, isChild, showStimuli, stop, testFinished]
  );

  // What stimuli to show
  const shouldIShow: (id: number) => boolean = (id) => {
    if (isChild) {
      switch (id) {
        case 0:
          if (showStimuliId === 0) return true;
          return false;
        case 1:
          if (showStimuliId === 128) return true;
          return false;
        default:
          return false;
      }
    } else {
      switch (id) {
        case 0:
          if (showStimuliId === 0 || showStimuliId === 128) return true;
          return false;
        case 1:
          if (showStimuliId === 1 || showStimuliId === 129) return true;
          return false;
        case 2:
          if (showStimuliId === 2 || showStimuliId === 130) return true;
          return false;
        case 3:
          if (showStimuliId === 3 || showStimuliId === 131) return true;
          return false;
        default:
          return false;
      }
    }
  };

  // Fetch stimuli images from offline storage
  const getStimuliImages: () => JSX.Element[] = () => {
    const stimuliPictures = images.map((stimuli, index) => {
      return (
        <StimuliImage
          key={index}
          visible={shouldIShow(index)}
          stimuliUrl={stimuli.url}
        />
      );
    });
    return stimuliPictures;
  };

  // Fullscren checker
  React.useEffect(() => {
    function onFullscreenChange() {
      if (isFullscreen && document.fullscreenElement === null) {
        stopTest("Closed Fullscreen mode");
        setTestDone(true);
        setIsFullscreen(Boolean(document.fullscreenElement));
      }
      setIsFullscreen(Boolean(document.fullscreenElement));
    }
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, [isFullscreen, stopTest]);

  // Test start countdown
  React.useEffect(() => {
    let timerId: NodeJS.Timer;
    if (started && duration > 0) {
      timerId = setInterval(() => {
        setDuration((prev) => prev - 1);
      }, 1000);
    }

    if (duration <= 0 && startTime === 0) {
      const startTimestamp = new Date().getTime();
      startRecording();
      setStartTime(startTimestamp);
      start();
    }

    return () => {
      clearInterval(timerId);
    };
  }, [duration, start, startRecording, startTime, started]);

  // when component mounts
  React.useEffect(() => {
    setStarted(true);

    return () => {
      setStarted(false);
    };
  }, []);

  React.useEffect(() => {
    const blurFunction = () => {
      stopTest("Closed Fullscreen mode");
      setTestDone(true);
      setIsFullscreen(Boolean(document.fullscreenElement));
    };

    window.addEventListener("blur", blurFunction);
    return () => {
      window.removeEventListener("blur", blurFunction);
    };
  }, [stopTest]);

  const imageElements: JSX.Element[] = getStimuliImages();
  if (!imageElements) return null;

  return (
    <div className="w-full h-full bg-test-background" ref={testRef}>
      {showStats && (
        <Stats
          fps={fps}
          time={Math.round(time / 1000)}
          testDone={testDone}
          deltaTime={deltaTime}
          paused={paused}
        />
      )}
      <div className="absolute top-0 flex w-screen h-full justify-center items-center pointer-events-none">
        {imageElements}
        {started && duration - SECONDS_START_DELAY > 0 && (
          <p className="text-display-large">{duration - SECONDS_START_DELAY}</p>
        )}
      </div>
    </div>
  );
};

export default Test;
