import { FaceDetection } from "@mediapipe/face_detection";
import * as cameraUtils from "@mediapipe/camera_utils";
import { VERSION as FaceDetectionVersion } from "@mediapipe/face_detection";

import { NormalizedRect, Results } from "./types";
import { drawBoundingBox } from "./canvas";
import { defaultOptions } from "./defaults";

const cdnBaseUrl = "https://cdn.jsdelivr.net/npm/@mediapipe";

export const centerBox: NormalizedRect = {
  xCenter: 0.5,
  yCenter: 0.5,
  height: 0.75,
  width: 0.75,
  rotation: 0,
  rectId: 1,
};

export const isRectangleWithinBounds = (
  rect: NormalizedRect,
  bounds: NormalizedRect
): boolean => {
  // Calculate top left corner of rect
  const rectTopLeft = {
    x: rect.xCenter - rect.width * 0.5,
    y: rect.yCenter - rect.height * 0.5,
  };

  // Calculate top left corner of bounds
  const boundsTopLeft = {
    x: bounds.xCenter - bounds.width * 0.5,
    y: bounds.yCenter - bounds.height * 0.5,
  };

  // top left corner within bounds?
  if (rectTopLeft.x < boundsTopLeft.x || rectTopLeft.y < boundsTopLeft.y) {
    return false;
  }

  // bottom right corner within bounds?
  if (
    rectTopLeft.x + rect.width > boundsTopLeft.x + bounds.width ||
    rectTopLeft.y + rect.height > boundsTopLeft.y + bounds.height
  ) {
    return false;
  }
  return true;
};

let previousTime = 0;

// TODO: Fix in a better way later
let videoElement = null as HTMLVideoElement | null;
let canvasElement = null as HTMLCanvasElement | null;
let canvasCtx = null as CanvasRenderingContext2D | null | undefined;

function onResults(results: any, callback: any, shouldShowVideo: boolean) {
  // Draw the overlays.
  const time = new Date().getTime();
  const deltaTime = time - previousTime;
  const secondsPassed = deltaTime / 1000;
  const fps = Math.round(1 / secondsPassed);
  previousTime = time;

  let withinBounds = false;
  if (results.detections[0]) {
    withinBounds = isRectangleWithinBounds(
      results.detections[0].boundingBox,
      centerBox
    );
  }

  if (canvasCtx && canvasElement && shouldShowVideo) {
    canvasCtx.save();
    canvasCtx.canvas.width = 1280;
    canvasCtx.canvas.height = 720;
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );
    if (canvasCtx && centerBox)
      drawBoundingBox(
        canvasCtx,
        centerBox,
        results?.detections[0]?.boundingBox || false
      );
  }

  if (!canvasCtx) return;
  canvasCtx?.restore();

  callback({ ...results, withinBounds, fps });
}

let camera = null as cameraUtils.Camera | null;
let faceDetection = null as any;

export const stopCamera = () => {
  if (camera) {
    camera.stop();
    camera = null;
  }
  if (faceDetection) {
    faceDetection.close();
    faceDetection = null;
  }
};

export const startCamera = ({
  canvasElementReact,
  videoElementReact,
  onResultsCallback,
  size,
  shouldShowVideo = true,
}: {
  canvasElementReact: HTMLCanvasElement;
  videoElementReact: HTMLVideoElement;
  onResultsCallback: (results: Results) => void;
  skipCompatibilityCheck?: boolean;
  size?: { w: number; h: number } | undefined;
  shouldShowVideo?: boolean;
}) => {
  canvasElement = canvasElementReact;
  canvasCtx = canvasElement?.getContext("2d");
  videoElement = videoElementReact;

  const width = size?.w || 1280;
  const height = size?.h || 720;

  if (camera || faceDetection) {
    stopCamera();
  }

  camera = new cameraUtils.Camera(videoElement, {
    onFrame: async () => {
      if (!videoElement || !faceDetection) return;
      await faceDetection.send({ image: videoElement });
    },
    width: width,
    height: height,
  });

  try {
    camera.start();
  } catch {
    console.warn("Camera: Problem with starting camera");
  }

  faceDetection = new FaceDetection({
    locateFile: (file: any) => {
      return `${cdnBaseUrl}/${"face_detection"}@${FaceDetectionVersion}/${file}`;
    },
  });
  faceDetection.setOptions({
    ...defaultOptions,
  });
  faceDetection.onResults((results: any) => {
    onResults(results, onResultsCallback, shouldShowVideo);
  });
};
