import * as React from "react";

type StimuliImageProps = {
  visible: boolean;
  stimuliUrl: string;
};

export const StimuliImage: React.FC<StimuliImageProps> = ({
  visible,
  stimuliUrl,
}) => {
  return (
    <div className="absolute pointer-events-none">
      <img
        className={[
          visible ? "visible" : "hidden",
          "h-[30vh] w-auto pointer-events-none",
        ].join(" ")}
        src={stimuliUrl}
        alt=""
      />
    </div>
  );
};
