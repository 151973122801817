import { ReactElement } from "react";
import { Image } from "components";
import { IconInfoCircle } from "assets/images";

type AlertProps = {
  type?: "info" | "error";
  children: ReactElement | ReactElement[] | string;
};

const Paragraph: React.FC<AlertProps> = ({
  type = "info",
  children,
}: AlertProps): ReactElement => {
  const classes = type === "error" ? "bg-error-95" : "bg-primary-95";

  return (
    <div
      className={[classes, "w-full mb-4 flex flex-row p-4 rounded-md"].join(
        " "
      )}
    >
      <div className="w-[20px] min-w-[20px] mr-4 mt-[2px]">
        <Image src={IconInfoCircle} />
      </div>
      {children}
    </div>
  );
};

export default Paragraph;
