import React from "react";
import { Button, GridConsumer, Input, RadioForm, Title } from "components";
import { VISUAL_AID } from "contexts/indexedDBContext/enums";
import { useDataProvider } from "contexts";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const VisualAidPage = () => {
  const navigate = useNavigate();
  const { updateTestMetaData } = useDataProvider();

  const [hasVisualAid, setHasVisualAid] = React.useState<boolean | undefined>(
    undefined
  );
  const [visualAid, setVisualAid] = React.useState<string>("");
  const [otherValue, setOtherValue] = React.useState<string>("");

  const canProceed =
    hasVisualAid === false ||
    (hasVisualAid && visualAid && visualAid !== VISUAL_AID.OTHER) ||
    (hasVisualAid && visualAid === VISUAL_AID.OTHER && otherValue.length > 0);

  const usingVisualAid = (value: boolean) => {
    if (value) {
      setHasVisualAid(true);
    } else {
      setHasVisualAid(false);
      setVisualAid("");
    }
  };

  const setVisualAidFunc = (value: string) => {
    setVisualAid(value);
    if (value !== VISUAL_AID.OTHER) {
      setOtherValue("");
    }
  };

  const proceed = () => {
    if (hasVisualAid) {
      let visualAidString: string;
      if (visualAid === VISUAL_AID.OTHER) {
        visualAidString = VISUAL_AID.OTHER + ":" + otherValue;
      } else {
        visualAidString = visualAid;
      }
      updateTestMetaData({
        visionAid: visualAidString,
      });
    } else {
      updateTestMetaData({
        visionAid: false,
      });
    }
    navigate(ROUTES.DemographicsOptInOutPage);
  };

  const visualAidChoices = hasVisualAid ? (
    <>
      <Title
        size="medium"
        align="left"
        text="What kind of visual aid are you using?"
      />
      <RadioForm
        choices={[
          { value: VISUAL_AID.GLASSES, label: "Glasses" },
          { value: VISUAL_AID.CONTACT_LENSES, label: "Contact Lenses" },
          { value: VISUAL_AID.OTHER, label: "Other" },
        ]}
        groupName="visualAid"
        valueCallback={(value) => setVisualAidFunc(value || "")}
      />
    </>
  ) : (
    <></>
  );

  return (
    <GridConsumer>
      <Title text="Visual aid" />
      <Title
        size="medium"
        align="left"
        text="Are you using a visual aid right now?"
      />
      <RadioForm
        choices={["No", "Yes"]}
        groupName="hasVisualAid"
        valueCallback={(value) => usingVisualAid(value === "Yes")}
      />
      {visualAidChoices}
      <div className="w-full">
        {visualAid === VISUAL_AID.OTHER && (
          <Input
            placeholder="Specify what visual aid you are using"
            label="Please specify"
            setValue={setOtherValue}
            value={otherValue}
          />
        )}
      </div>
      <Button
        label="Next"
        fullWidth
        disabled={!canProceed}
        onClick={() => proceed()}
        stickToBottom
      />
    </GridConsumer>
  );
};

export default VisualAidPage;
