import React from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDown } from "assets/svgs";
import { usePopper } from "react-popper";

type ListBoxProps = {
  values: string[];
  label: string;
  startValue: string;
  callback: React.Dispatch<React.SetStateAction<string>>;
};

const ListBox = ({ values, label, startValue, callback }: ListBoxProps) => {
  const [selected, setSelected] = React.useState(startValue);
  const [referenceElement, setReferenceElement] = React.useState<
    HTMLElement | null | undefined
  >(null);
  const [popperElement, setPopperElement] = React.useState<
    HTMLElement | null | undefined
  >(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  const onChange = (value: string) => {
    callback(value);
    setSelected(value);
  };

  return (
    <div className="w-full">
      <Listbox value={selected} onChange={onChange}>
        <div className="relative mt-1">
          <div className="absolute -top-[10px] left-3 bg-white px-1 text-body-medium">
            {label}
          </div>
          <Listbox.Button
            role={"button"}
            ref={setReferenceElement}
            className="w-full hover:bg-primary-90 h-16 cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-primary-40 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-30 sm:text-sm  border border-secondary-40"
          >
            {({ open }) => (
              <>
                <span className="block truncate pl-1">
                  {selected.toString()}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-2 flex items-center pr-2">
                  <ChevronDown />
                </span>
              </>
            )}
          </Listbox.Button>
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              ref={setPopperElement}
              {...attributes.popper}
              style={styles.popper}
              className="w-full rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm"
            >
              <div className="bg-surface-light-surface2 pt-1 pb-1">
                {values.map((value, valueIdx) => (
                  <Listbox.Option
                    key={valueIdx}
                    className={({ active, selected }) =>
                      `hover:bg-primary-90 cursor-pointer select-none py-2 pl-4 pr-4 ${
                        active ? " text-black" : ""
                      }`
                    }
                    value={value}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium " : "font-normal"
                          }`}
                        >
                          {value}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
export default ListBox;
