import React from "react";
import {
  AbilityTestCompletePage,
  AbilityTestPage,
  AbilityTestStartPage,
  CompatibilityCheckPage,
  CompatibilityInfoPage,
  DemographicsOptInOutPage,
  EthnicityPage,
  FullTestCancelledPage,
  FullTestCompletedPage,
  FullTestPage,
  IntendedUsePage,
  IsHispanicPage,
  LabelPage,
  MedicationPage,
  ReadinessCheckPage,
  RoomEnvironmentPage,
  SexAndBirthPage,
  TestInstructionPage,
  VisualAidPage,
  VoucherInputPage,
} from "../pages";

const PATHS = {
  LabelPage: "LabelPage",
  IntendedUsePage: "IntendedUsePage",
  CompatibilityInfoPage: "CompatibilityInfoPage",
  CompatibilityCheckPage: "CompatibilityCheckPage",
  SexAndBirthPage: "SexAndBirthPage",
  MedicationPage: "MedicationPage",
  VisualAidPage: "VisualAidPage",
  DemographicsOptInOutPage: "DemographicsOptInOutPage",
  EyeColorPage: "EyeColorPage",
  IsHispanicPage: "IsHispanicPage",
  EthnicityPage: "EthnicityPage",
  RoomEnvironmentPage: "RoomEnvironmentPage",
  TestInstructionPage: "TestInstructionPage",
  ReadinessCheckPage: "ReadinessCheckPage",
  AbilityTestStartPage: "AbilityTestStartPage",
  AbilityTestPage: "AbilityTestPage",
  AbilityTestCompletePage: "AbilityTestCompletePage",
  FullTestPage: "FullTestPage",
  FullTestCompletedPage: "FullTestCompletedPage",
  FullTestCancelledPage: "FullTestCancelledPage",
};

// All pages are children to / (App.tsx), so they need a / in the pathname.
// This will add them to the entries in the list above.
const ROUTES = Object.values(PATHS).reduce(
  (obj: any, value) => ({
    ...obj,
    [value]: "/" + value,
  }),
  {}
);

const reverseTable: any = {
  [ROUTES.LabelPage]: "/",
  [ROUTES.CompatibilityInfoPage]: ROUTES.LabelPage,
  [ROUTES.CompatibilityCheckPage]: ROUTES.CompatibilityInfoPage,
  [ROUTES.SexAndBirthPage]: ROUTES.CompatibilityInfoPage,
  [ROUTES.MedicationPage]: ROUTES.SexAndBirthPage,
  [ROUTES.VisualAidPage]: ROUTES.MedicationPage,
  [ROUTES.DemographicsOptInOutPage]: ROUTES.VisualAidPage,
  [ROUTES.IsHispanicPage]: ROUTES.DemographicsOptInOutPage,
  [ROUTES.EthnicityPage]: ROUTES.IsHispanicPage,
  [ROUTES.RoomEnvironmentPage]: ROUTES.DemographicsOptInOutPage,
  [ROUTES.TestInstructionPage]: ROUTES.RoomEnvironmentPage,
  [ROUTES.ReadinessCheckPage]: ROUTES.TestInstructionPage,
  [ROUTES.AbilityTestStartPage]: ROUTES.ReadinessCheckPage,
  [ROUTES.AbilityTestPage]: ROUTES.AbilityTestStartPage,
  [ROUTES.AbilityTestCompletePage]: ROUTES.AbilityTestStartPage,
};

type RouteType = {
  index?: boolean;
  path?: string;
  element: any;
};

const routes: RouteType[] = [
  { index: true, element: <VoucherInputPage /> },
  { path: PATHS.LabelPage, element: <LabelPage /> },
  { path: PATHS.IntendedUsePage, element: <IntendedUsePage /> },
  { path: PATHS.CompatibilityInfoPage, element: <CompatibilityInfoPage /> },
  { path: PATHS.CompatibilityCheckPage, element: <CompatibilityCheckPage /> },
  { path: PATHS.SexAndBirthPage, element: <SexAndBirthPage /> },
  { path: PATHS.MedicationPage, element: <MedicationPage /> },
  { path: PATHS.VisualAidPage, element: <VisualAidPage /> },
  {
    path: PATHS.DemographicsOptInOutPage,
    element: <DemographicsOptInOutPage />,
  },
  // { path: PATHS.EyeColorPage, element: <EyeColorPage /> }, // Not implemented
  { path: PATHS.IsHispanicPage, element: <IsHispanicPage /> },
  { path: PATHS.EthnicityPage, element: <EthnicityPage /> },
  { path: PATHS.RoomEnvironmentPage, element: <RoomEnvironmentPage /> },
  { path: PATHS.TestInstructionPage, element: <TestInstructionPage /> },
  { path: PATHS.ReadinessCheckPage, element: <ReadinessCheckPage /> },
  { path: PATHS.AbilityTestStartPage, element: <AbilityTestStartPage /> },
  { path: PATHS.AbilityTestPage, element: <AbilityTestPage /> },
  { path: PATHS.AbilityTestCompletePage, element: <AbilityTestCompletePage /> },
  { path: PATHS.FullTestPage, element: <FullTestPage /> },
  { path: PATHS.FullTestCompletedPage, element: <FullTestCompletedPage /> },
  { path: PATHS.FullTestCancelledPage, element: <FullTestCancelledPage /> },
];

export { ROUTES, routes, reverseTable };
