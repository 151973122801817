import { Title, Paragraph, Gap, Image, Headline } from "components";
import {
  TestInstruction1_1,
  TestInstruction4_1,
  BlueCircleDontPress,
  BlueCirclePress,
  BlueCircle,
  BlueSquareDontPress,
  BlueSquarePress,
  BlueSquare,
  RedCircleDontPress,
  RedCirclePress,
  RedCircle,
  RedSquareDontPress,
  RedSquare,
  SpacebarClickPhoto,
} from "assets/images";

const gapSize = 30;

const Step0 = () => {
  return (
    <>
      <Title text="Test instructions" />
      <Paragraph align="left">
        Now that you are set up and ready to take the test, let's have a look
        into how the test works.
      </Paragraph>
    </>
  );
};

const Step1 = () => {
  return (
    <>
      <Title text="Test Instructions" size="medium" />
      <Paragraph>Step 1/4</Paragraph>
      <Title text="What the test is about" size="large" />
      <Paragraph align="left" type="bulletList">
        This is a test to measure your attention, impulse control and your
        activity levels.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        It will take 20 minutes.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        The camera will track your movements but it will not capture images.
      </Paragraph>
      <Image src={TestInstruction1_1} />
      <Gap size={gapSize} vertical />
    </>
  );
};

const Step2 = () => {
  return (
    <>
      <Title text="Test Instructions" size="large" />
      <Paragraph>Step 2/4</Paragraph>
      <Headline text="How it works" size="medium" />
      <Paragraph align="left">
        Let's look more in detail what you'll see and what you're supposed to
        do.
      </Paragraph>
      <Headline text="What you'll see" size="small" />
      <Paragraph align="left">
        These symbols will appear individually on the screen during the test: a
        red square, red circle, blue square or a blue circle.
      </Paragraph>
      <Image src={RedSquare} />
      <Gap vertical size={gapSize} />
      <Image src={RedCircle} />
      <Gap vertical size={gapSize} />
      <Image src={BlueSquare} />
      <Gap vertical size={gapSize} />
      <Image src={BlueCircle} />
      <Gap vertical size={gapSize} />
      <Headline text="What you'll need to do" size="small" />
      <Paragraph align="left" type="bulletList">
        Your task is to press the spacebar when a symbol of the same shape and
        color is repeated.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        For example, a blue circle followed by a blue circle means you should
        press, as it is the same shape and color.
      </Paragraph>
      <Gap vertical size={gapSize} />
      <Image src={BlueCircleDontPress} />
      <Gap vertical size={gapSize} />
      <Image src={BlueCirclePress} />
      <Gap vertical size={gapSize} />
      <Paragraph align="left" type="bulletList">
        If a symbol of the same shape and color is repeated more than once, you
        should press every time. That means if you see a shape appear a second
        and a third time you should press both the second and the third time.
      </Paragraph>
      <Image src={BlueCircleDontPress} />
      <Gap vertical size={gapSize} />
      <Image src={BlueCirclePress} />
      <Gap vertical size={gapSize} />
      <Image src={BlueCirclePress} />
      <Gap vertical size={gapSize} />
      <Gap size={gapSize} />
      <Headline text="What not to do" size="small" />
      <Paragraph align="left">
        If the blue circle was followed by a blue square you should not press,
        as they are only the same color but not the same shape.
      </Paragraph>
      <Image src={BlueCircleDontPress} />
      <Gap vertical size={gapSize} />
      <Image src={BlueSquareDontPress} />
      <Gap vertical size={gapSize} />
      <Headline text="How you'll need to press" size="small" />
      <Paragraph align="left" type="bulletList">
        Press as fast and correctly as possible.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Symbols will be shown fast. Don’t worry, you are still allowed to press
        after the symbol disappears.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        Only use your dominant hand, the one you usually write with, when
        pressing the spacebar.
      </Paragraph>
      <Image src={SpacebarClickPhoto} />
      <Gap vertical size={gapSize} />
    </>
  );
};

const Step3 = () => {
  return (
    <>
      <Title text="Test Instructions" size="large" />
      <Paragraph>Step 3/4</Paragraph>
      <Headline text="Example" size="medium" />
      <Paragraph align="left">
        Let's look at an example of how the symbols might appear in the screen
        and what you have to do.
      </Paragraph>
      <Image src={RedCircleDontPress} />
      <Gap vertical size={gapSize} />
      <Image src={RedCirclePress} />
      <Gap vertical size={gapSize} />
      <Image src={RedCirclePress} />
      <Gap vertical size={gapSize} />
      <Image src={BlueSquareDontPress} />
      <Gap vertical size={gapSize} />
      <Image src={RedSquareDontPress} />
      <Gap vertical size={gapSize} />
      <Image src={BlueSquareDontPress} />
      <Gap vertical size={gapSize} />
      <Image src={BlueSquarePress} />
      <Gap vertical size={gapSize} />
      <Image src={BlueSquarePress} />
      <Gap vertical size={gapSize} />
    </>
  );
};

const Step4 = () => {
  return (
    <>
      <Title text="Test Instructions" size="large" />
      <Paragraph>Step 4/4</Paragraph>
      <Headline text="Final tips" size="medium" />

      <Paragraph align="left" type="bulletList">
        Don't worry if you make mistakes, everyone makes them. Just try to do
        your best.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        You might get a little tired or bored but try to stay focused during the
        whole test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        The test will stop by itself after 20 minutes.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        You will now do a 1-minute Ability Test to confirm that you have
        understood the instructions.
      </Paragraph>
      <Image src={TestInstruction4_1} />
      <Gap size={gapSize} vertical />
    </>
  );
};

export { Step0, Step1, Step2, Step3, Step4 };
