import React from "react";
import { ReactElement } from "react";

type RadioFormProps = {
  choices: string[] | { value: string; label: string }[];
  groupName: string;
  valueCallback: (value: string | undefined) => void;
  className?: string;
};

const RadioForm: React.FC<RadioFormProps> = ({
  choices,
  groupName,
  valueCallback,
  className,
}: RadioFormProps): ReactElement => {
  const [value, setValue] = React.useState<string | undefined>(undefined);

  const radioContainerClasses =
    "flex h-[64px] mb-4 p-4 border border-secondary-40 rounded-lg items-center cursor-pointer";

  const checkedCointainerClasses = "bg-secondary-40 text-white border-0";
  const hoverClasses = "hover:bg-secondary-90 hover:text-black";
  const checkedHoverClasses = "hover:text-white";

  const radioClasses =
    "appearance-none w-5 h-5 outline outline-2 -outline-offset-2 rounded-full mr-2 text-secondary-40 bg-white hover:bg-secondary-90";
  const checkedRadioClasses =
    "text-white outline-white before:absolute before:bg-white before:w-3 before:h-3 before:rounded-full before:m-1 bg-secondary-40 hover:bg-secondary-40";

  const onOptionChange = (e: React.FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    valueCallback(e.currentTarget.value);
  };

  return (
    <div className={className + " w-full"}>
      {choices.map((choice, index) => {
        let label = typeof choice === "string" ? choice : choice.label;
        let choiceValue = typeof choice === "string" ? choice : choice.value;
        return (
          <label
            key={index}
            htmlFor={groupName + "_" + choiceValue}
            className={[
              choices.length - 1 === index && "mb-0",
              radioContainerClasses,
              choiceValue === value && checkedCointainerClasses,
              choiceValue === value ? checkedHoverClasses : hoverClasses,
            ].join(" ")}
          >
            <input
              type="radio"
              id={groupName + "_" + choiceValue}
              name={groupName}
              checked={choiceValue === value}
              value={choiceValue}
              className={[
                radioClasses,
                choiceValue === value && checkedRadioClasses,
              ].join(" ")}
              onChange={onOptionChange}
            />
            {label}
          </label>
        );
      })}
    </div>
  );
};

export default RadioForm;
