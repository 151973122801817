import { IconCircleAlert, IconCircleCheck } from "assets/images";
import Image from "components/Image/";
import { useEffect, useState } from "react";

export enum ICON_KEYS {
  CHECK = "check",
  ALERT = "alert",
}

type IconProps = {
  iconKey: ICON_KEYS;
  className?: string;
};

const iconSize = "80px";

const Icon: React.FC<IconProps> = ({ iconKey, className }: IconProps) => {
  const [icon, setIcon] = useState<string>("");
  const classes = className ?? "";

  useEffect(() => {
    switch (iconKey) {
      case ICON_KEYS.CHECK: {
        setIcon(IconCircleCheck);
        break;
      }
      case ICON_KEYS.ALERT: {
        setIcon(IconCircleAlert);
        break;
      }
    }
  }, [iconKey]);

  return (
    <div className={classes} style={{ width: iconSize }}>
      <Image src={icon} />
    </div>
  );
};

export default Icon;
