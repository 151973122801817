import { ReactElement } from "react";
import React from "react";

type InputProps = {
  error?: string;
  value: string;
  label: string;
  placeholder?: string;
  setValue: (arg0: string) => void;
};

const Input: React.FC<InputProps> = ({
  error,
  value,
  label,
  placeholder,
  setValue,
}: InputProps): ReactElement => {
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div
      className={[
        !error && focused && "border-[#5FB2F2]",
        error ? "border-error-40" : "border-black",
        "relative flex mb-4 h-[56px] pb-4 p-4 w-full border rounded mt-6 ",
      ].join(" ")}
    >
      <div
        className={[
          !error && focused ? "text-[#5FB2F2]" : "text-black",
          error ? "text-error-40" : "text-black",
          "text-label-small absolute -top-2 left-2 bg-white px-2",
        ].join(" ")}
      >
        {label}
      </div>
      <input
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={[
          "w-full text-2xl focus:outline-none focus:border-b-[#5FB2F2] mt-auto",
        ].join(" ")}
      />
    </div>
  );
};

export default Input;
