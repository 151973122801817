function Keyboard() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 801 329">
      <rect width="800.312" height="328.333" fill="#EEEFF1" rx="27.85"></rect>
      <path
        fill="#fff"
        stroke="url(#paint0_angular_526_31046)"
        strokeWidth="0.733"
        d="M772.463 8.428h-27.117a4.031 4.031 0 00-4.031 4.031v40.31a4.031 4.031 0 004.031 4.03h41.042a4.031 4.031 0 004.031-4.03V26.383c0-9.916-8.039-17.956-17.956-17.956z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint1_angular_526_31046)"
        strokeWidth="0.733"
        d="M732.153 8.428h-41.041a4.031 4.031 0 00-4.031 4.031v40.31a4.031 4.031 0 004.031 4.03h41.041a4.03 4.03 0 004.031-4.03v-40.31a4.03 4.03 0 00-4.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint2_angular_526_31046)"
        strokeWidth="0.733"
        d="M623.687 8.428h-41.042a4.031 4.031 0 00-4.031 4.031v40.31a4.031 4.031 0 004.031 4.03h41.042a4.03 4.03 0 004.03-4.03v-40.31a4.03 4.03 0 00-4.03-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint3_angular_526_31046)"
        strokeWidth="0.733"
        d="M515.219 8.428h-41.042a4.031 4.031 0 00-4.031 4.031v40.31a4.031 4.031 0 004.031 4.03h41.042a4.031 4.031 0 004.031-4.03v-40.31a4.031 4.031 0 00-4.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint4_angular_526_31046)"
        strokeWidth="0.733"
        d="M406.752 8.428H365.71a4.031 4.031 0 00-4.031 4.031v40.31a4.031 4.031 0 004.031 4.03h41.042a4.031 4.031 0 004.031-4.03v-40.31a4.031 4.031 0 00-4.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint5_angular_526_31046)"
        strokeWidth="0.733"
        d="M298.284 8.428h-41.041a4.03 4.03 0 00-4.031 4.031v40.31a4.03 4.03 0 004.031 4.03h41.041a4.031 4.031 0 004.031-4.03v-40.31a4.031 4.031 0 00-4.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint6_angular_526_31046)"
        strokeWidth="0.733"
        d="M189.817 8.428h-41.041a4.031 4.031 0 00-4.031 4.031v40.31a4.031 4.031 0 004.031 4.03h41.041a4.03 4.03 0 004.031-4.03v-40.31a4.03 4.03 0 00-4.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint7_angular_526_31046)"
        strokeWidth="0.733"
        d="M677.92 8.428h-41.042a4.031 4.031 0 00-4.031 4.031v40.31a4.031 4.031 0 004.031 4.03h41.042a4.031 4.031 0 004.031-4.03v-40.31a4.031 4.031 0 00-4.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint8_angular_526_31046)"
        strokeWidth="0.733"
        d="M569.452 8.428h-41.041a4.03 4.03 0 00-4.031 4.031v40.31a4.03 4.03 0 004.031 4.03h41.041a4.031 4.031 0 004.031-4.03v-40.31a4.031 4.031 0 00-4.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint9_angular_526_31046)"
        strokeWidth="0.733"
        d="M460.985 8.428h-41.041a4.031 4.031 0 00-4.031 4.031v40.31a4.031 4.031 0 004.031 4.03h41.041a4.03 4.03 0 004.031-4.03v-40.31a4.03 4.03 0 00-4.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint10_angular_526_31046)"
        strokeWidth="0.733"
        d="M352.519 8.428h-41.042a4.031 4.031 0 00-4.031 4.031v40.31a4.031 4.031 0 004.031 4.03h41.042a4.03 4.03 0 004.03-4.03v-40.31a4.03 4.03 0 00-4.03-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint11_angular_526_31046)"
        strokeWidth="0.733"
        d="M244.051 8.428h-41.042a4.031 4.031 0 00-4.031 4.031v40.31a4.031 4.031 0 004.031 4.03h41.042a4.031 4.031 0 004.031-4.03v-40.31a4.031 4.031 0 00-4.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint12_angular_526_31046)"
        strokeWidth="0.733"
        d="M135.584 8.428H94.542a4.03 4.03 0 00-4.03 4.031v40.31a4.03 4.03 0 004.03 4.03h41.042a4.031 4.031 0 004.031-4.03v-40.31a4.031 4.031 0 00-4.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint13_angular_526_31046)"
        strokeWidth="0.733"
        d="M81.35 8.428H27.117c-9.917 0-17.956 8.04-17.956 17.956v26.384a4.03 4.03 0 004.03 4.031h68.16a4.03 4.03 0 004.03-4.03v-40.31a4.03 4.03 0 00-4.03-4.03z"
      ></path>
      <circle
        cx="765.866"
        cy="32.247"
        r="14.658"
        fill="#fff"
        stroke="url(#paint14_angular_526_31046)"
        strokeWidth="1.466"
      ></circle>
      <path
        fill="#fff"
        stroke="url(#paint15_angular_526_31046)"
        strokeWidth="0.733"
        d="M13.191 61.196h41.042a4.03 4.03 0 014.03 4.03v40.31a4.031 4.031 0 01-4.03 4.031H13.191a4.031 4.031 0 01-4.03-4.031v-40.31a4.03 4.03 0 014.03-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint16_angular_526_31046)"
        strokeWidth="0.733"
        d="M67.426 61.196h41.041a4.03 4.03 0 014.031 4.03v40.31a4.03 4.03 0 01-4.031 4.031H67.426a4.031 4.031 0 01-4.031-4.031v-40.31a4.03 4.03 0 014.03-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint17_angular_526_31046)"
        strokeWidth="0.733"
        d="M175.893 61.196h41.041a4.031 4.031 0 014.031 4.03v40.31a4.031 4.031 0 01-4.031 4.031h-41.041a4.03 4.03 0 01-4.031-4.031v-40.31a4.03 4.03 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint18_angular_526_31046)"
        strokeWidth="0.733"
        d="M284.36 61.196h41.042a4.031 4.031 0 014.031 4.03v40.31a4.031 4.031 0 01-4.031 4.031H284.36a4.031 4.031 0 01-4.031-4.031v-40.31a4.031 4.031 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint19_angular_526_31046)"
        strokeWidth="0.733"
        d="M392.827 61.196h41.042a4.031 4.031 0 014.031 4.03v40.31a4.031 4.031 0 01-4.031 4.031h-41.042a4.031 4.031 0 01-4.031-4.031v-40.31a4.031 4.031 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint20_angular_526_31046)"
        strokeWidth="0.733"
        d="M501.294 61.196h41.042a4.03 4.03 0 014.03 4.03v40.31a4.03 4.03 0 01-4.03 4.031h-41.042a4.031 4.031 0 01-4.031-4.031v-40.31a4.031 4.031 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint21_angular_526_31046)"
        strokeWidth="0.733"
        d="M609.762 61.196h41.041a4.03 4.03 0 014.031 4.03v40.31a4.03 4.03 0 01-4.031 4.031h-41.041a4.03 4.03 0 01-4.031-4.031v-40.31a4.03 4.03 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint22_angular_526_31046)"
        strokeWidth="0.733"
        d="M121.659 61.196h41.042a4.031 4.031 0 014.031 4.03v40.31a4.031 4.031 0 01-4.031 4.031h-41.042a4.031 4.031 0 01-4.031-4.031v-40.31a4.031 4.031 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint23_angular_526_31046)"
        strokeWidth="0.733"
        d="M230.126 61.196h41.042a4.03 4.03 0 014.03 4.03v40.31a4.03 4.03 0 01-4.03 4.031h-41.042a4.031 4.031 0 01-4.031-4.031v-40.31a4.031 4.031 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint24_angular_526_31046)"
        strokeWidth="0.733"
        d="M338.594 61.196h41.041a4.03 4.03 0 014.031 4.03v40.31a4.03 4.03 0 01-4.031 4.031h-41.041a4.031 4.031 0 01-4.031-4.031v-40.31a4.031 4.031 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint25_angular_526_31046)"
        strokeWidth="0.733"
        d="M447.061 61.196h41.041a4.031 4.031 0 014.031 4.03v40.31a4.031 4.031 0 01-4.031 4.031h-41.041a4.03 4.03 0 01-4.031-4.031v-40.31a4.03 4.03 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint26_angular_526_31046)"
        strokeWidth="0.733"
        d="M555.528 61.196h41.042a4.031 4.031 0 014.031 4.03v40.31a4.031 4.031 0 01-4.031 4.031h-41.042a4.031 4.031 0 01-4.031-4.031v-40.31a4.031 4.031 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint27_angular_526_31046)"
        strokeWidth="0.733"
        d="M663.995 61.196h41.042a4.031 4.031 0 014.031 4.03v40.31a4.031 4.031 0 01-4.031 4.031h-41.042a4.031 4.031 0 01-4.031-4.031v-40.31a4.031 4.031 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint28_angular_526_31046)"
        strokeWidth="0.733"
        d="M718.229 61.196h68.158a4.03 4.03 0 014.031 4.03v40.31a4.03 4.03 0 01-4.031 4.031h-68.158a4.031 4.031 0 01-4.031-4.031v-40.31a4.031 4.031 0 014.031-4.03z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint29_angular_526_31046)"
        strokeWidth="0.733"
        d="M786.388 113.231h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint30_angular_526_31046)"
        strokeWidth="0.733"
        d="M732.153 113.231h-41.041a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.041a4.03 4.03 0 004.031-4.031v-40.309a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint31_angular_526_31046)"
        strokeWidth="0.733"
        d="M623.687 113.231h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.03 4.03 0 004.03-4.031v-40.309a4.03 4.03 0 00-4.03-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint32_angular_526_31046)"
        strokeWidth="0.733"
        d="M515.219 113.231h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint33_angular_526_31046)"
        strokeWidth="0.733"
        d="M406.752 113.231H365.71a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint34_angular_526_31046)"
        strokeWidth="0.733"
        d="M298.285 113.231h-41.041a4.03 4.03 0 00-4.031 4.031v40.309a4.03 4.03 0 004.031 4.031h41.041a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint35_angular_526_31046)"
        strokeWidth="0.733"
        d="M189.817 113.231h-41.041a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.041a4.03 4.03 0 004.031-4.031v-40.309a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint36_angular_526_31046)"
        strokeWidth="0.733"
        d="M677.92 113.231h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint37_angular_526_31046)"
        strokeWidth="0.733"
        d="M569.453 113.231h-41.041a4.03 4.03 0 00-4.031 4.031v40.309a4.03 4.03 0 004.031 4.031h41.041a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint38_angular_526_31046)"
        strokeWidth="0.733"
        d="M460.985 113.231h-41.041a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.041a4.03 4.03 0 004.031-4.031v-40.309a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint39_angular_526_31046)"
        strokeWidth="0.733"
        d="M352.519 113.231h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.03 4.03 0 004.03-4.031v-40.309a4.03 4.03 0 00-4.03-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint40_angular_526_31046)"
        strokeWidth="0.733"
        d="M244.051 113.231h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint41_angular_526_31046)"
        strokeWidth="0.733"
        d="M135.584 113.231H94.542a4.031 4.031 0 00-4.03 4.031v40.309a4.031 4.031 0 004.03 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint42_angular_526_31046)"
        strokeWidth="0.733"
        d="M81.35 113.231H13.192a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.03 4.031h68.16a4.031 4.031 0 004.03-4.031v-40.309a4.031 4.031 0 00-4.03-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint43_angular_526_31046)"
        strokeWidth="0.733"
        d="M787.121 166h-82.084a4.03 4.03 0 00-4.03 4.031v40.308a4.03 4.03 0 004.03 4.031h82.084a4.03 4.03 0 004.031-4.031v-40.308a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint44_angular_526_31046)"
        strokeWidth="0.733"
        d="M691.112 166h-41.041a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.041a4.03 4.03 0 004.031-4.031v-40.308a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint45_angular_526_31046)"
        strokeWidth="0.733"
        d="M582.645 166h-41.042a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.042a4.03 4.03 0 004.03-4.031v-40.308a4.03 4.03 0 00-4.03-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint46_angular_526_31046)"
        strokeWidth="0.733"
        d="M474.178 166h-41.042a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.042a4.03 4.03 0 004.031-4.031v-40.308a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint47_angular_526_31046)"
        strokeWidth="0.733"
        d="M365.71 166h-41.042a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.042a4.03 4.03 0 004.031-4.031v-40.308A4.03 4.03 0 00365.71 166z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint48_angular_526_31046)"
        strokeWidth="0.733"
        d="M257.243 166h-41.041a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.041a4.03 4.03 0 004.031-4.031v-40.308a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint49_angular_526_31046)"
        strokeWidth="0.733"
        d="M148.775 166h-41.041a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.041a4.03 4.03 0 004.031-4.031v-40.308a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint50_angular_526_31046)"
        strokeWidth="0.733"
        d="M636.878 166h-41.042a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.042a4.03 4.03 0 004.031-4.031v-40.308a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint51_angular_526_31046)"
        strokeWidth="0.733"
        d="M528.411 166H487.37a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.041a4.03 4.03 0 004.031-4.031v-40.308a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint52_angular_526_31046)"
        strokeWidth="0.733"
        d="M419.944 166h-41.041a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.041a4.03 4.03 0 004.031-4.031v-40.308a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint53_angular_526_31046)"
        strokeWidth="0.733"
        d="M312.21 166h-41.042a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.042a4.03 4.03 0 004.031-4.031v-40.308A4.03 4.03 0 00312.21 166z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint54_angular_526_31046)"
        strokeWidth="0.733"
        d="M203.01 166h-41.042a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.031 4.031h41.042a4.03 4.03 0 004.031-4.031v-40.308A4.03 4.03 0 00203.01 166z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint55_angular_526_31046)"
        strokeWidth="0.733"
        d="M94.542 166h-81.35a4.03 4.03 0 00-4.031 4.031v40.308a4.03 4.03 0 004.03 4.031h81.351a4.03 4.03 0 004.031-4.031v-40.308a4.03 4.03 0 00-4.03-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint56_angular_526_31046)"
        strokeWidth="0.733"
        d="M787.12 218.767h-109.2a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h109.2a4.03 4.03 0 004.031-4.031v-40.309a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint57_angular_526_31046)"
        strokeWidth="0.733"
        d="M663.995 218.767h-41.042a4.03 4.03 0 00-4.03 4.031v40.309a4.03 4.03 0 004.031 4.031h41.041a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint58_angular_526_31046)"
        strokeWidth="0.733"
        d="M555.528 218.767h-41.041a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.041a4.03 4.03 0 004.031-4.031v-40.309a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint59_angular_526_31046)"
        strokeWidth="0.733"
        d="M447.061 218.767h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.03 4.03 0 004.03-4.031v-40.309a4.03 4.03 0 00-4.03-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint60_angular_526_31046)"
        strokeWidth="0.733"
        d="M338.594 218.767h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint61_angular_526_31046)"
        strokeWidth="0.733"
        d="M230.126 218.767h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint62_angular_526_31046)"
        strokeWidth="0.733"
        d="M609.762 218.767H568.72a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint63_angular_526_31046)"
        strokeWidth="0.733"
        d="M501.294 218.767h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint64_angular_526_31046)"
        strokeWidth="0.733"
        d="M392.827 218.767h-41.041a4.03 4.03 0 00-4.031 4.031v40.309a4.03 4.03 0 004.031 4.031h41.041a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint65_angular_526_31046)"
        strokeWidth="0.733"
        d="M284.359 218.767h-41.041a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.041a4.03 4.03 0 004.031-4.031v-40.309a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint66_angular_526_31046)"
        strokeWidth="0.733"
        d="M175.893 218.767h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.031 4.031h41.042a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint67_angular_526_31046)"
        strokeWidth="0.733"
        d="M121.659 218.767H13.192a4.031 4.031 0 00-4.031 4.031v40.309a4.031 4.031 0 004.03 4.031H121.66a4.031 4.031 0 004.031-4.031v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint68_angular_526_31046)"
        strokeWidth="0.733"
        d="M677.92 271.535h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.03 4.03 0 004.031 4.03h41.042a4.03 4.03 0 004.031-4.03v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint69_angular_526_31046)"
        strokeWidth="0.733"
        d="M786.388 271.535h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.03 4.03 0 004.031 4.03h27.117c9.917 0 17.956-8.039 17.956-17.955v-26.384a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <mask
        id="path-72-outside-1_526_31046"
        width="51"
        height="50"
        x="686.447"
        y="270.901"
        fill="#000"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M686.447 270.901H737.447V320.901H686.447z"></path>
        <path
          fillRule="evenodd"
          d="M687.447 275.566a3.665 3.665 0 013.665-3.665h41.041a3.665 3.665 0 013.665 3.665v19.788H687.447v.733-20.521zm0 20.521h48.371v-.733 20.521a3.664 3.664 0 01-3.665 3.664h-41.041a3.664 3.664 0 01-3.665-3.664v-19.788z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M687.447 275.566a3.665 3.665 0 013.665-3.665h41.041a3.665 3.665 0 013.665 3.665v19.788H687.447v.733-20.521zm0 20.521h48.371v-.733 20.521a3.664 3.664 0 01-3.665 3.664h-41.041a3.664 3.664 0 01-3.665-3.664v-19.788z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint70_angular_526_31046)"
        d="M687.447 295.354v-.733h-.733v.733h.733zm0 .733h-.733v.733h.733v-.733zm48.371 0v.733h.733v-.733h-.733zm0-.733h.733v-.733h-.733v.733zm-44.706-24.186a4.398 4.398 0 00-4.398 4.398h1.466a2.932 2.932 0 012.932-2.932v-1.466zm41.041 0h-41.041v1.466h41.041v-1.466zm4.398 4.398a4.398 4.398 0 00-4.398-4.398v1.466a2.932 2.932 0 012.932 2.932h1.466zm0 19.788v-19.788h-1.466v19.788h1.466zm-1.1.733h.367v-1.466h-.367v1.466zm-47.637 0h47.637v-1.466h-47.637v1.466zm-.367 0h.367v-1.466h-.367v1.466zm.733 0v-.733h-1.466v.733h1.466zm-.733.733v-1.466 1.466zm-.733-21.254v20.521h1.466v-20.521h-1.466zm1.466 20.521h-1.466 1.466zm-.733.733h48.371v-1.466h-48.371v1.466zm49.104-.733v-.733h-1.466v.733h1.466zm-.733 0v-1.466 1.466zm-.733-.733v.733h1.466v-.733h-1.466zm0 .733v19.788h1.466v-19.788h-1.466zm0 19.788a2.93 2.93 0 01-2.932 2.931v1.466a4.398 4.398 0 004.398-4.397h-1.466zm-2.932 2.931h-41.041v1.466h41.041v-1.466zm-41.041 0a2.932 2.932 0 01-2.932-2.931h-1.466a4.398 4.398 0 004.398 4.397v-1.466zm-2.932-2.931v-19.788h-1.466v19.788h1.466z"
        mask="url(#path-72-outside-1_526_31046)"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint71_angular_526_31046)"
        strokeWidth="0.733"
        d="M623.687 271.535h-41.042a4.031 4.031 0 00-4.031 4.031v40.309a4.03 4.03 0 004.031 4.03h41.042a4.03 4.03 0 004.03-4.03v-40.309a4.03 4.03 0 00-4.03-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint72_angular_526_31046)"
        strokeWidth="0.733"
        d="M569.453 271.535h-54.966a4.031 4.031 0 00-4.031 4.031v40.309a4.03 4.03 0 004.031 4.03h54.966a4.03 4.03 0 004.031-4.03v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint73_angular_526_31046)"
        strokeWidth="0.733"
        d="M230.859 271.535h-54.966a4.031 4.031 0 00-4.031 4.031v40.309a4.03 4.03 0 004.031 4.03h54.966a4.03 4.03 0 004.031-4.03v-40.309a4.03 4.03 0 00-4.031-4.031z"
      ></path>
      <path
        className="keyboardSpacebar"
        fill="#D4E4F6"
        stroke="url(#paint74_angular_526_31046)"
        strokeWidth="0.733"
        d="M501.294 271.535H243.318a4.031 4.031 0 00-4.031 4.031v40.309a4.03 4.03 0 004.031 4.03h257.976a4.03 4.03 0 004.031-4.03v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint75_angular_526_31046)"
        strokeWidth="0.733"
        d="M162.7 271.535h-41.041a4.03 4.03 0 00-4.031 4.031v40.309a4.03 4.03 0 004.031 4.03H162.7a4.03 4.03 0 004.031-4.03v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint76_angular_526_31046)"
        strokeWidth="0.733"
        d="M108.467 271.535H67.425a4.031 4.031 0 00-4.03 4.031v40.309a4.03 4.03 0 004.03 4.03h41.042a4.03 4.03 0 004.031-4.03v-40.309a4.031 4.031 0 00-4.031-4.031z"
      ></path>
      <path
        fill="#fff"
        stroke="url(#paint77_angular_526_31046)"
        strokeWidth="0.733"
        d="M54.233 271.535H13.192a4.031 4.031 0 00-4.031 4.031v27.117c0 9.512 7.71 17.222 17.223 17.222h27.85a4.03 4.03 0 004.03-4.03v-40.309a4.031 4.031 0 00-4.03-4.031z"
      ></path>
      <defs>
        <radialGradient
          id="paint0_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 765.867 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 711.633 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint2_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 603.166 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint3_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 494.698 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint4_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 386.231 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint5_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 277.763 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint6_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 169.297 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint7_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 657.399 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint8_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 548.931 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint9_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 440.465 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint10_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 331.998 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint11_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 223.53 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint12_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 115.063 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint13_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -37.7437 0 47.271 32.614)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint14_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(90 366.81 399.057) scale(13.9249)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint15_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 33.712 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint16_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 87.947 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint17_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 196.413 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint18_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 304.881 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint19_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 413.348 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint20_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 521.815 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint21_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 630.283 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint22_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 142.18 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint23_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 250.647 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint24_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 359.115 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint25_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 467.581 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint26_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 576.049 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint27_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 684.516 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint28_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -37.7437 0 752.308 85.381)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint29_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 765.867 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint30_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 711.633 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint31_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 603.166 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint32_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 494.698 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint33_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 386.231 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint34_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 277.764 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint35_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 169.297 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint36_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 657.399 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint37_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 548.932 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint38_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 440.465 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint39_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 331.998 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint40_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 223.53 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint41_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 115.063 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint42_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -37.7437 0 47.271 137.416)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint43_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -44.7061 0 746.079 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint44_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 670.592 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint45_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 562.124 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint46_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 453.657 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint47_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 345.189 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint48_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 236.722 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint49_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 128.255 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint50_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 616.357 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint51_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 507.89 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint52_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 399.424 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint53_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 291.689 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint54_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 182.489 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint55_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -44.3397 0 53.867 190.185)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint56_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -58.2645 0 732.52 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint57_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 643.474 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint58_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 535.008 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint59_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 426.54 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint60_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 318.073 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint61_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 209.605 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint62_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 589.241 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint63_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 480.773 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint64_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 372.306 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint65_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 263.839 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint66_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 155.372 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint67_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -57.8981 0 67.425 242.953)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint68_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 657.399 295.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint69_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 765.867 295.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint70_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 711.633 295.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint71_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 603.166 295.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint72_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -31.1477 0 541.97 295.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint73_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -31.1477 0 203.376 295.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint74_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -132.653 0 372.306 295.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint75_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 142.179 295.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint76_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 87.946 295.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
        <radialGradient
          id="paint77_angular_526_31046"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 23.8188 -24.1853 0 33.713 295.72)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.33"></stop>
          <stop offset="0.526" stopOpacity="0.2"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default Keyboard;
