import { Button, GridConsumer, Image, Paragraph, Title } from "components";
import { useDataProvider } from "contexts";
import { TestCancelledIllustration } from "assets/images";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const FullTestCancelledPage = () => {
  const { lastFullTestResult, nFullTests } = useDataProvider();

  if (lastFullTestResult !== "SUCCESSFUL" && nFullTests >= 3) {
    return (
      <GridConsumer>
        <Title text="Test interrupted" />
        <Paragraph align="left">
          The test session has been interrupted too many times. Please restart
          the session.
        </Paragraph>
        <Image src={TestCancelledIllustration} className="mt-6" />
        <div className="mt-auto w-full">
          <Button
            label="Restart test session"
            onClick={() => document.location.reload()}
            fullWidth
          />
        </div>
      </GridConsumer>
    );
  }

  const Message = () => {
    switch (lastFullTestResult) {
      case "FACE_NOT_DETECTED":
        return (
          <>
            <Title text="We couldn't detect your face" />
            <Paragraph>
              You left the viewport for too long. Please try again, and attempt
              to stay within the viewport.
            </Paragraph>
            <Image src={TestCancelledIllustration} className="mt-6" />
            <Link to={ROUTES.FullTestPage}>
              <Button label="Restart" fullWidth stickToBottom />
            </Link>
          </>
        );
      case "TEST_INTERRUPTED":
        return (
          <>
            <Title text="Test was interrupted" />
            <Paragraph>
              The test was cancelled in a unexpected way. Please restart the
              test to proceed.
            </Paragraph>
            <Image src={TestCancelledIllustration} className="mt-6" />
            <Link to={ROUTES.FullTestPage}>
              <Button label="Restart" fullWidth stickToBottom />
            </Link>
          </>
        );
      case "KEYBOARD_PRESS_NOT_DETECTED":
        return (
          <>
            <Title text="Test was interrupted" />
            <Paragraph>
              We did not receive any keyboard presses for a long duration of
              time. Please make sure that your keyboard is working and try the
              test again.
            </Paragraph>
            <Image src={TestCancelledIllustration} className="mt-6" />
            <Link to={ROUTES.FullTestPage}>
              <Button label="Restart" fullWidth stickToBottom />
            </Link>
          </>
        );
      default:
        return (
          <>
            <Title text="Test was interrupted" />
            <Paragraph>
              The test was cancelled in a unexpected way. Please restart the
              Test to proceed.
            </Paragraph>
            <Image src={TestCancelledIllustration} className="mt-6" />
            <Link to={ROUTES.FullTestPage}>
              <Button label="Restart" fullWidth stickToBottom />
            </Link>
          </>
        );
    }
  };

  return (
    <GridConsumer>
      <Message />
    </GridConsumer>
  );
};

export default FullTestCancelledPage;
