import { useState, useEffect, useRef, useCallback } from "react";
import { Title, GridConsumer, Button, Paragraph, Icon } from "components";
import { ICON_KEYS } from "components/Icon/Icon";
import { useDataProvider } from "contexts";
import { UPLOAD_STATUS } from "contexts/dataContext";

const FullTestCompletedPage = () => {
  const [uploadStatus, setUploadStatus] = useState<UPLOAD_STATUS>(
    UPLOAD_STATUS.INITIAL
  );
  const hasRun = useRef<boolean>(false);
  const { uploadData, saveToIndexDB, uploadIndexDBData } = useDataProvider();

  const tryUpload = useCallback(() => {
    if (!navigator.onLine) return setUploadStatus(UPLOAD_STATUS.NO_CONNECTION);
    setUploadStatus(UPLOAD_STATUS.UPLOADING);
    uploadData().then((result) => {
      setUploadStatus(result ? result : UPLOAD_STATUS.FAILED);
      if (result === UPLOAD_STATUS.COMPLETED) {
        uploadIndexDBData();
      } else {
        saveToIndexDB();
      }
    });
  }, [uploadData, saveToIndexDB, uploadIndexDBData]);

  useEffect(() => {
    if (hasRun.current) return; // Only run once in dev mode
    if (!navigator.onLine) {
      saveToIndexDB();
      setUploadStatus(UPLOAD_STATUS.NO_CONNECTION);
      hasRun.current = true;
      return;
    }
    tryUpload();
    hasRun.current = true;
  }, [saveToIndexDB, uploadData, tryUpload]);

  const Message = () => {
    switch (uploadStatus) {
      case UPLOAD_STATUS.COMPLETED:
        return (
          <>
            <Title text="All done" />
            <Icon iconKey={ICON_KEYS.CHECK} className={"mb-20 mt-20"} />
            <Paragraph>
              Your data has been uploaded! Your provider will contact you with
              more information. Make sure to rest if you feel tired.
            </Paragraph>
            <Button
              label="Done"
              onClick={() => document.location.reload()}
              fullWidth
              stickToBottom
            />
          </>
        );
      case UPLOAD_STATUS.UPLOADING:
        return (
          <>
            <Title text="Uploading data" />
            <Paragraph>
              The test is now complete! Please wait for the data to upload.
            </Paragraph>
          </>
        );
      case UPLOAD_STATUS.NO_CONNECTION:
        return (
          <>
            <Title text="No connection" />
            <Icon iconKey={ICON_KEYS.ALERT} className={"mb-20 mt-20"} />
            <Paragraph>
              You seem to be offline. Please set up an internet connection and
              come back here to make sure that your result is uploaded. Make
              sure to rest if you feel tired.
            </Paragraph>
            <Button
              label="Retry"
              onClick={() => tryUpload()}
              fullWidth
              stickToBottom
            />
          </>
        );
      case UPLOAD_STATUS.STORED_TEST: {
        return (
          <>
            <Title text="Upload test" />
            <Icon iconKey={ICON_KEYS.ALERT} className={"mb-20 mt-20"} />
            <Paragraph>
              You seem to have a test result still waiting to be uploaded to the
              clinic. You need to upload the result before starting a new test
              session.
            </Paragraph>
            <Button
              label="Upload"
              onClick={() => tryUpload()}
              fullWidth
              stickToBottom
            />
          </>
        );
      }
      case UPLOAD_STATUS.FAILED: {
        return (
          <>
            <Title text="Something went wrong" />
            <Icon iconKey={ICON_KEYS.ALERT} className={"mb-20 mt-20"} />
            <Paragraph>
              An error has occurred while uploading your test result. Please try
              again later. Your results are saved on this device to be uploaded
              later. Make sure to rest if you feel tired.
            </Paragraph>
            <Button
              label="Retry"
              onClick={() => tryUpload()}
              fullWidth
              stickToBottom
            />
          </>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <GridConsumer>
      <Message />
    </GridConsumer>
  );
};

export default FullTestCompletedPage;
