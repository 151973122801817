import * as React from "react";

type StatsProps = {
  fps: number;
  time: number;
  testDone: boolean;
  deltaTime: number;
  paused: boolean;
};

export const Stats: React.FC<StatsProps> = ({
  fps,
  time,
  testDone,
  deltaTime,
  paused,
}) => {
  return (
    <div className="pt-12 p-4 text-left flex flex-col">
      <p>fps: {fps}</p>
      <p>
        time: {time}s <br></br>
      </p>
      <p>Test Done: {testDone ? "true" : "false"}</p>
      <p>Paused: {paused ? "true" : "false"}</p>
      <p>Time between renders: {deltaTime}ms</p>
    </div>
  );
};
