import { Step0, Step1, Step2, Step3, Step4 } from "./AdultSteps";
import {
  ChildStep0,
  ChildStep1,
  ChildStep2,
  ChildStep3,
  ChildStep4,
} from "./ChildSteps";
import { Button, GridConsumer } from "components";
import { useDataProvider } from "contexts";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";
import { BackButton } from "../../components/BackButton";

const TestInstructionPage = () => {
  const [step, setStep] = React.useState<number>(0);
  const navigate = useNavigate();
  const { isChild } = useDataProvider();

  const adultScreens = [<Step0 />, <Step1 />, <Step2 />, <Step3 />, <Step4 />];
  const childScreens = [
    <ChildStep0 />,
    <ChildStep1 />,
    <ChildStep2 />,
    <ChildStep3 />,
    <ChildStep4 />,
  ];

  const screens = isChild ? childScreens : adultScreens;

  const onClick = () => {
    if (screens.length === step + 1) {
      navigate(ROUTES.ReadinessCheckPage);
      return;
    }
    setStep((prev) => prev + 1);
    window.scrollTo(0, 0);
  };

  let buttonText = "Next";
  if (step === 0) buttonText = "Get ready for the test";

  return (
    <GridConsumer>
      <BackButton
        onClick={() =>
          step === 0 ? navigate(ROUTES.RoomEnvironmentPage) : setStep(step - 1)
        }
      />
      {screens[step]}
      <Button
        label={buttonText}
        onClick={() => onClick()}
        fullWidth
        stickToBottom
      />
    </GridConsumer>
  );
};

export default TestInstructionPage;
