import React from "react";
import { Button, GridConsumer, RadioForm, Title } from "components";
import { useDataProvider } from "contexts";
import { ETHNICITY, RACE } from "contexts/indexedDBContext/enums";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const DemographicsOptInOutPage = () => {
  const navigate = useNavigate();
  const { updateTestMetaData } = useDataProvider();
  const [optIn, setOptIn] = React.useState<boolean | undefined>();
  console.log("optIn", optIn);

  const proceed = () => {
    if (!optIn) {
      updateTestMetaData({
        ethnicity: ETHNICITY.PREFER_NOT_TO_SAY,
        races: [RACE.PREFER_NOT_TO_SAY],
      });
      navigate(ROUTES.RoomEnvironmentPage);
    } else {
      navigate(ROUTES.IsHispanicPage);
    }
  };

  return (
    <GridConsumer>
      <Title text="Ethnicity" />
      <Title
        align="left"
        size="medium"
        text="Would you feel comfortable in sharing information on your ethnicity? We seek this information in order to improve our data."
      />
      <RadioForm
        choices={["No", "Yes"]}
        groupName="optOut"
        valueCallback={(value) => setOptIn(value === "Yes")}
      />
      <Button
        label="Next"
        fullWidth
        disabled={optIn === undefined}
        onClick={() => proceed()}
        stickToBottom
      />
    </GridConsumer>
  );
};

export default DemographicsOptInOutPage;
