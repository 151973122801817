import { Button, GridConsumer, Paragraph, Title } from "components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const AbilityTestStartPage = () => {
  const navigate = useNavigate();
  const disabled = false;
  return (
    <GridConsumer>
      <Title text="You are ready to start the Ability Test" />
      <Paragraph align="left">
        Now that you've read the instructions, you are ready to start the 1
        minute Ability Test.
      </Paragraph>
      <Button
        label="Start Ability Test"
        fullWidth
        stickToBottom
        onClick={() => navigate(ROUTES.AbilityTestPage)}
        disabled={disabled}
      />
    </GridConsumer>
  );
};

export default AbilityTestStartPage;
