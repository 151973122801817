import * as React from "react";
import { Buffer } from "buffer";
import {
  AbilityTest,
  DBTestDataRecordFile,
  Test,
  TestDataRecordFile,
  TestMetaData,
  TEST_COMPLETED_CASES,
} from "./indexedDBContext/types";
import { IndexDB as IndexedDB } from "./indexedDBContext/indexDB";
import { dbSave, dbRemove, dbGetAll } from "./indexedDBContext/apiMethods";
import { COLLECTION_NAMES, ETHNICITY, RACE } from "./indexedDBContext/enums";

declare global {
  interface Window {
    debug: () => void;
    nStimulisToShow: (arg0?: number) => void;
  }
}

type DataProviderProps = { children: React.ReactNode };

type JwtData = {
  dob: string; // Date of birth
  sex: string; // Sex
  ref: string; // Reference
  bt: string; // Birth Date
  exp: string; // Expiration time
  iat: string; // Issued at
};

type AuthProps = {
  voucher: string;
  onSuccess: () => void;
};

type ConfigProps = {
  data: {
    sex: string;
    dateOfBirth: string;
  };
  onSuccess: () => void;
  onFail: () => void;
};

type ConfigPostData = {
  sex: string | undefined;
  dateOfBirth: string | undefined;
};

type Config = {
  reference: string;
  uploadUrl: string;
  stimuli: { id: string; url: string }[];
  ability: object[];
  full: object[];
};

type Images = {
  id: string;
  url: string;
}[];

export enum UPLOAD_STATUS {
  INITIAL = "INITIAL",
  UPLOADING = "UPLOADING",
  COMPLETED = "COMPLETED",
  NO_CONNECTION = "NO_CONNECTION",
  STORED_TEST = "STORED_TEST",

  // FAILED status is kind of a user-facing bucket term for errors on our side.
  FAILED = "FAILED",
}

function parseJwtData(jwt: string) {
  const data =
    jwt &&
    JSON.parse(Buffer.from(jwt.split(".")[1], "base64").toString("ascii"));
  return data;
}

const DataContext = React.createContext<
  | {
      getConfig: ({ data, onSuccess, onFail }: ConfigProps) => void;
      auth: ({ voucher, onSuccess }: AuthProps) => void;
      saveFullTest: (arg0: Test) => void;
      saveAbilityTest: (arg0: AbilityTest) => void;
      uploadData: () => Promise<UPLOAD_STATUS | undefined>;
      clearError: () => void;
      updateTestMetaData: (arg0: Partial<TestMetaData>) => void;
      uploadIndexDBData(): Promise<void>;
      saveToIndexDB(): void;
      lastAbilityTestResult: TEST_COMPLETED_CASES | undefined;
      lastFullTestResult: TEST_COMPLETED_CASES | undefined;
      nAbilityTests: number;
      nFullTests: number;
      nStimulisToShow: number | undefined;
      jwtData: JwtData | undefined;
      isChild: boolean | undefined;
      error: string;
      loading: boolean;
      config: any;
      images: Images;
      debugMode: boolean;
    }
  | undefined
>(undefined);

function DataProvider({ children }: DataProviderProps) {
  const [debugMode, setDebugMode] = React.useState(true);
  const [jwt, setJwt] = React.useState<string | undefined>(undefined);
  const [jwtData, setJwtData] = React.useState<JwtData | undefined>(undefined);
  const [config, setConfig] = React.useState<Config | undefined>(undefined);
  const [abilityTests, setAbilityTests] = React.useState<AbilityTest[]>([]);
  const [lastAbilityTestResult, setLastAbilityTestResult] = React.useState<
    TEST_COMPLETED_CASES | undefined
  >(undefined);
  const [lastFullTestResult, setLastFullTestResult] = React.useState<
    TEST_COMPLETED_CASES | undefined
  >(undefined);
  const [fullTests, setFullTests] = React.useState<Test[]>([]);
  const [images, setImages] = React.useState<Images>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [nStimulisToShow, setNStimulisToShow] = React.useState<
    number | undefined
  >(undefined);
  const [error, setError] = React.useState<string>("");
  const [isChild, setIsChild] = React.useState<boolean | undefined>(undefined);
  const [indexDB, setIndexDB] = React.useState<IndexedDB | undefined>(
    undefined
  );
  const [testMetaData, setTestMetaData] = React.useState<TestMetaData>({
    ethnicity: ETHNICITY.PREFER_NOT_TO_SAY,
    races: [RACE.PREFER_NOT_TO_SAY],
    visionAid: false,
    timestampLastMedicalIntake: null,
    onMedication: false,
  });

  window.debug = () => {
    setDebugMode(!debugMode);
    console.log("debugmode set to: ", !debugMode);
  };
  window.nStimulisToShow = (number?: number) => {
    if (typeof number === "number") {
      setNStimulisToShow(number);
      console.log("Stimulis to show set to: ", number);
    } else {
      setNStimulisToShow(undefined);
      console.log("Stimulis to show set to show all");
    }
  };

  const provider = {
    getConfig,
    auth,
    saveFullTest,
    saveAbilityTest,
    uploadData,
    clearError,
    updateTestMetaData,
    debugMode,
    uploadIndexDBData,
    saveToIndexDB,
    lastAbilityTestResult,
    lastFullTestResult,
    nAbilityTests: abilityTests.length,
    nFullTests: fullTests.length,
    isChild,
    nStimulisToShow,
    jwtData,
    error,
    loading,
    config,
    images,
  };

  function updateTestMetaData(update: Partial<TestMetaData>) {
    const metaData = { ...testMetaData, ...update };
    setTestMetaData(metaData);
  }

  function clearError() {
    setError("");
  }

  function getTestDataRecord() {
    if (!config) return;

    const dataFile: TestDataRecordFile = {
      // TODO: CORRECT THIS INFORMATION
      testDataRecordSchemaVersion: "V1", // <---
      fullTests,
      abilityTests,
      reference: config.reference,
      systemInfo: {
        UDI: "(01)07350103930098(8012)wt230310080000", // <---
      },
      metaData: testMetaData,
    };

    return dataFile;
  }

  function saveFullTest(result: Test) {
    setFullTests([...fullTests, result]);
    if (result.qualityCheckError)
      setLastFullTestResult(result.qualityCheckError);
    else setLastFullTestResult("SUCCESSFUL");
  }

  function saveAbilityTest(result: AbilityTest) {
    setAbilityTests([...abilityTests, result]);
    if (result.qualityCheckError)
      setLastAbilityTestResult(result.qualityCheckError);
    else if (result.testEvaluation.passed === true)
      setLastAbilityTestResult("SUCCESSFUL");
    else setLastAbilityTestResult("INCORRECT_RESPONSES");
  }

  async function saveToIndexDB() {
    const testDataRecord = getTestDataRecord() as DBTestDataRecordFile;
    if (!config) return console.warn("ERROR IN SAVING TO INDEX DB, NO CONFIG");
    if (!testDataRecord)
      return console.warn("ERROR IN SAVING TO INDEX DB, NO DATA");
    if (!indexDB)
      return console.warn("ERROR IN SAVING TO INDEX DB, NO INDEXDB");

    // one could make a more sophisticated system to update individual
    // data items (i.e abilityTests) between sessions
    // within the same database entry.
    // but for now we just delete the old and save the new if
    // a new session is finished and there is still old data.
    // this is very unlikely to happen in practice since we
    // upload old data at the sex/age page,
    // right after we confirm that the user has a connection.
    testDataRecord.uploadUrl = config.uploadUrl;
    await dbRemove(
      indexDB,
      COLLECTION_NAMES.TEST_DATA_RECORD_FILES,
      config.reference
    );
    await dbSave(
      indexDB,
      COLLECTION_NAMES.TEST_DATA_RECORD_FILES,
      testDataRecord,
      config.reference
    );
  }

  async function uploadIndexDBData() {
    if (!indexDB)
      return console.warn("ERROR IN UPLOADING INDEXDB DATA, NO INDEXDB");
    const allRecords = await dbGetAll(
      indexDB,
      COLLECTION_NAMES.TEST_DATA_RECORD_FILES
    );
    const allRecordsArray = await allRecords.toCollection().toArray();
    for (const testRecord of allRecordsArray) {
      fetch(testRecord.uploadUrl, {
        method: "PUT",
        body: JSON.stringify(testRecord),
      })
        .then((response) => {
          if (response.ok) {
            dbRemove(
              indexDB,
              COLLECTION_NAMES.TEST_DATA_RECORD_FILES,
              testRecord.reference
            );
          } else {
            console.warn(
              "Could not post locally saved data, keeping for later"
            );
          }
        })
        .catch((error) => {
          console.warn(error.message);
        });
    }
  }

  async function uploadData(): Promise<UPLOAD_STATUS> {
    const data = getTestDataRecord();
    setLoading(true);
    const uploadUrl = config?.uploadUrl;

    if (!uploadUrl) {
      console.warn("Can't find the uploadUrl");
      return UPLOAD_STATUS.FAILED;
    }

    // check if data is valid towards schema vith ajv?
    // Do the upload

    const request = fetch(uploadUrl, {
      method: "PUT",
      body: JSON.stringify(data),
    });

    const response = await request;
    if (!response.ok) {
      console.log(
        `Problem with posting data:  ${JSON.stringify(response.status)}`
      );
      setLoading(false);
      return UPLOAD_STATUS.FAILED;
    }
    console.log("posting data returned OK");
    setLoading(false);
    return UPLOAD_STATUS.COMPLETED;
  }

  async function auth({ voucher, onSuccess }: AuthProps) {
    setLoading(true);

    const request = fetch("https://api.stage.qbmt.qbtech.com/v1/auth", {
      method: "POST",
      mode: "cors",
      referrerPolicy: "no-referrer",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ voucher }),
    });

    request
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const token = data.bearer;
            if (token) {
              setJwt(token);
              setJwtData(parseJwtData(token));
              setError("");
              onSuccess();
            } else {
              setError("Can not find bearer on token");
            }
          });
        } else {
          const msg = `auth response error:  ${JSON.stringify(
            response.status
          )}`;
          setError(msg);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }

  async function getConfig({
    data,
    onSuccess,
    onFail,
  }: {
    data: ConfigPostData;
    onSuccess: () => void;
    onFail: () => void;
  }) {
    if (!jwtData) return;
    const { ref, bt, dob, sex } = jwtData;
    if (!ref || !bt || !dob || !sex) {
      console.warn("No reference, businessType, date of birth or sex");
      return;
    }

    setLoading(true);
    const request = fetch("https://api.stage.qbmt.qbtech.com/v1/config", {
      method: "POST",
      mode: "cors",
      referrerPolicy: "no-referrer",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "content-type": "application/json",
        "Accept-Encoding": "gzip, deflate, br",
      },
      body: JSON.stringify({
        sex: data.sex,
        dateOfBirth: data.dateOfBirth,
        testType: "QBTEST_CLASSIC",
        UDI: "(01)7350103930012(8012)wt2212221710",
      }),
    });

    request
      .then((response) => {
        if (response.ok) {
          const data = response.json();

          data.then((data) => {
            if (data) {
              setConfig(data);
              if (data.stimuli)
                fetchImages(data.stimuli).then((images) => {
                  setImages(images);
                  onSuccess();
                });
            }
          });
        } else {
          const msg = `get config response error:  ${JSON.stringify(
            response.status
          )}`;
          setError(msg);
          onFail();
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }

  function fetchImages(
    stimulis: { id: string; url: string }[]
  ): Promise<Images> {
    return new Promise(function (resolve, reject) {
      function fetchImage(imageUrl: string): Promise<Blob> {
        return fetch(imageUrl, {
          mode: "cors",
          referrerPolicy: "no-referrer",
        }).then((response) => {
          if (response.type === "opaque")
            console.warn("Opaque answer from image fetch");
          return response.blob();
        });
      }

      Promise.all(
        stimulis?.map((stimuli) => {
          return fetchImage(stimuli.url).then((result) => {
            return { id: stimuli.id, blob: result };
          });
        })
      )
        .then((result) => {
          const imgUrls = result.map((result) => {
            const imageObjectURL = URL.createObjectURL(result.blob);
            return { id: result.id, url: imageObjectURL };
          });
          resolve(imgUrls);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  React.useEffect(() => {
    if (!config) return;
    setIsChild(config.stimuli.length === 2 ? true : false);
  }, [config]);

  React.useEffect(() => {
    setIndexDB(new IndexedDB());
  }, []);

  return (
    <DataContext.Provider value={provider}>{children}</DataContext.Provider>
  );
}
function useDataProvider() {
  const context = React.useContext(DataContext);
  if (context === undefined) {
    throw new Error("useDataProvider must be used within a DataProvider");
  }
  return context;
}

export { DataProvider, useDataProvider };
