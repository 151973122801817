import { useRef } from "react";

const FPS_THRESHOLD = 20;

const useCheckFPS = () => {
  const totalNumberOfFrames = useRef<number>(0);
  const averageFPS = useRef<number>(0);
  const isFPSAcceptable = useRef<boolean>(false);

  const getAverageFPS = (fps: number) => {
    totalNumberOfFrames.current++;
    averageFPS.current =
      (averageFPS.current * (totalNumberOfFrames.current - 1) + fps) /
      totalNumberOfFrames.current;

    // Logging for debugging/testing purposes, remove later
    if (totalNumberOfFrames.current % 100 === 0) {
      console.log("Average FPS (every 100 frames)", averageFPS.current);
    }

    if (averageFPS.current >= FPS_THRESHOLD) {
      isFPSAcceptable.current = true;
    }
    if (averageFPS.current < FPS_THRESHOLD) {
      isFPSAcceptable.current = false;
    }
  };

  return { getAverageFPS, isFPSAcceptable };
};

export default useCheckFPS;
