import { ReactElement } from "react";

type TitleProps = {
  size?: "small" | "medium" | "large";
  align?: "left" | "center" | "right";
  text: string;
};

const Title: React.FC<TitleProps> = ({
  text,
  align = "center",
  size = "large",
}: TitleProps): ReactElement => {
  let sizeClass = "text-title-medium";
  if (size === "large") sizeClass = "text-title-large";
  if (size === "small") sizeClass = "text-title-small";
  let alignClass = "text-center";
  if (align === "left") alignClass = "text-left";
  if (align === "right") alignClass = "text-right";

  return (
    <h3 className={[sizeClass, alignClass, "w-full mb-4 mt-6"].join(" ")}>
      {text}
    </h3>
  );
};

export default Title;
