// README:

// ---------------------------------------------------------------------------

// ---------------------------------------------------------------------------

import { useState, useEffect, useRef } from "react";
import DeviceDetector, { DeviceDetectorResult } from "device-detector-js";

const supportedBrowsers = [
  {
    client: "Chrome",
    version: "71",
  },
  {
    client: "Firefox",
    version: "64",
  },
  {
    client: "Safari",
    version: "16",
  },
  {
    client: "Opera",
    version: "64",
  },
  {
    client: "Edge",
    version: "79",
  },
];

const supportedOS = ["Windows", "Mac"];

const useCompatibilityCheck = () => {
  const [isCompatible, setIsCompatible] = useState(false);
  const [userAgent, setUserAgent] = useState({} as DeviceDetectorResult);
  const deviceDetector = useRef({} as DeviceDetector);

  const checkCompatibility = (device: DeviceDetectorResult) => {
    console.log("USER DEVICE (For Compatibility purposes): ", device);
    setUserAgent(device);
    if (!device || !device.os || !device.client) return false;
    if (!supportedOS.includes(device.os.name)) return false;
    if (!device.client.name || !device.client.version) return false;
    const browser = supportedBrowsers.find(
      (browser) => browser.client === device.client?.name
    );
    if (!browser) return false;
    console.log("USER DEVICE VERSION (parseInt): ", parseInt(browser.version));
    if (parseInt(device.client.version) < parseInt(browser.version)) {
      console.log("USER DEVICE REJECTED!");
      return false;
    }
    console.log("USER DEVICE OK!");
    return true;
  };
  useEffect(() => {
    deviceDetector.current = new DeviceDetector();
    const device = deviceDetector.current.parse(navigator.userAgent);
    setUserAgent(device);
    setIsCompatible(checkCompatibility(device));
  }, []);

  return { isCompatible, userAgent };
};

export default useCompatibilityCheck;
