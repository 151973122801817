import React from "react";
import { Button, GridConsumer, ListBox, RadioForm, Title } from "components";
import { useDataProvider } from "contexts";
import { getlocalIsoDateTime } from "utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const MedicationPage = () => {
  const { updateTestMetaData } = useDataProvider();
  const [canProceed, setCanProceed] = React.useState(false);
  const [medication, setMedication] = React.useState<boolean | undefined>(
    undefined
  );
  const [last24h, setLast24h] = React.useState<boolean | undefined>(undefined);
  const [hoursAgo, setHoursAgo] = React.useState<string>("");

  const hoursAgoValues = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12+",
  ];
  const navigate = useNavigate();

  const getTimeAtMedication = () => {
    const now = new Date().getTime();
    const hoursAgoNumber = Number(hoursAgo);
    if (isNaN(hoursAgoNumber)) return "";
    const timestampAtIntake = now - 3600 * hoursAgoNumber * 1000;
    const localIsoDateTime = getlocalIsoDateTime(timestampAtIntake);
    return localIsoDateTime;
  };

  const proceed = () => {
    const timeAtMedication = getTimeAtMedication();
    const onMedication = medication && last24h && timeAtMedication.length > 0;

    updateTestMetaData({
      onMedication: medication,
      timestampLastMedicalIntake: onMedication ? timeAtMedication : null,
    });
    navigate(ROUTES.VisualAidPage);
  };

  React.useEffect(() => {
    const isValid = () => {
      if (medication === false) return true;
      if (medication === true && last24h === false) return true;
      if (medication === true && last24h === true && hoursAgo) return true;
      return false;
    };
    setCanProceed(isValid());
  }, [hoursAgo, last24h, medication]);

  return (
    <GridConsumer>
      <Title text="Medication" />
      {/* Insert content here */}
      <Title
        size="medium"
        align="left"
        text="Are you using prescribed ADHD medication?"
      />
      <RadioForm
        choices={["No", "Yes"]}
        groupName="medication"
        valueCallback={(value) =>
          value === "Yes"
            ? setMedication(true)
            : [setMedication(false), setLast24h(undefined), setHoursAgo("")]
        }
      />

      <>
        {medication && (
          <>
            <Title
              size="medium"
              align="left"
              text="Did you take it within the last 24h?"
            />
            <RadioForm
              choices={["No", "Yes"]}
              groupName="lasttwentyfour"
              valueCallback={(value) =>
                value === "Yes"
                  ? setLast24h(true)
                  : [setLast24h(false), setHoursAgo("")]
              }
            />
          </>
        )}

        {last24h && (
          <>
            <Title
              size="medium"
              align="left"
              text="How many hours ago did you take it?"
            />
            <ListBox
              label="Hours/ago"
              startValue="-"
              values={hoursAgoValues}
              callback={setHoursAgo}
            />
          </>
        )}
      </>
      <Button
        label="Next"
        onClick={() => proceed()}
        fullWidth
        stickToBottom
        disabled={!canProceed}
      />
    </GridConsumer>
  );
};

export default MedicationPage;
