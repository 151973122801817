import Dexie from "dexie";
import { DBTestDataRecordFileTable } from "./types";

export class IndexDB extends Dexie {
  testDataRecordFiles!: DBTestDataRecordFileTable;
  constructor() {
    super("IndexDB");
    this.version(1).stores({
      testDataRecordFiles: "++id, reference",
    });
  }
}
