import React, { useEffect, useRef } from "react";
import { startCamera, stopCamera } from "utils/cam";
import { Results } from "utils/cam/types";
import { Keyboard, KeyboardReady } from "assets/svgs";

import { useCheckFPS, useCountDown } from "hooks";
import {
  Button,
  GridConsumer,
  KeyboardEventCatcher,
  Paragraph,
  Title,
} from "components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const DURATION = 5;

const progressPercent = (time: number) => {
  switch (time) {
    case 5:
      return 0;
    case 4:
      return 20;
    case 3:
      return 40;
    case 2:
      return 60;
    case 1:
      return 80;
    case 0:
      return 100;
  }
};

const ReadinessCheckPage = () => {
  const navigate = useNavigate();
  const canvasRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const withinBounds = useRef<boolean | undefined>(false);
  const [isFpsAcceptable, setIsFpsAcceptable] = React.useState<boolean>(false);
  const [isCameraReady, setIsCameraReady] = React.useState(false);
  const [isKeyboardReady, setIsKeyboardReady] = React.useState(false);
  const [shouldBeDisabled, setShouldBeDisabled] = React.useState(false);
  const [time, setTime] = React.useState(DURATION);
  const [startReadinessCheck, setStartReadinessCheck] = React.useState(false);

  const fpsCheck = useRef(useCheckFPS());

  const { startTimer, resetTimer, stopTimer } = useCountDown({
    duration: DURATION,
    stopOnReset: false,
    callback: (counter) => {
      if (time !== counter) {
        setIsCameraReady(counter <= 0);
        setTime(counter);
      }
    },
  });

  const onResultsCallback = React.useCallback(
    (results: Results) => {
      fpsCheck.current.getAverageFPS(results.fps);
      setIsFpsAcceptable(fpsCheck.current.isFPSAcceptable.current);
      if (!results.withinBounds && withinBounds.current) {
        setIsCameraReady(false);
        setIsKeyboardReady(false);
        resetTimer();
        stopTimer();
        withinBounds.current = results.withinBounds;
      } else if (!results.withinBounds) {
        withinBounds.current = results.withinBounds;
      } else if (results.withinBounds && !withinBounds.current) {
        withinBounds.current = results.withinBounds;
        startTimer();
      }
    },
    [resetTimer, startTimer, stopTimer]
  );

  const startCam = React.useCallback(() => {
    if (canvasRef.current && videoRef.current)
      startCamera({
        canvasElementReact: canvasRef.current,
        videoElementReact: videoRef.current,
        onResultsCallback: onResultsCallback,
        skipCompatibilityCheck: true,
      });
  }, [onResultsCallback]);

  useEffect(() => {
    setShouldBeDisabled(!(isCameraReady && isKeyboardReady && isFpsAcceptable));
  }, [isCameraReady, isKeyboardReady, isFpsAcceptable]);

  useEffect(() => {
    if (!withinBounds.current) {
      setIsKeyboardReady(false);
      return;
    }
  }, [withinBounds]);

  useEffect(() => {
    if (startReadinessCheck) {
      startCam();
    }
    return () => {
      stopCamera();
    };
  }, [startCam, startReadinessCheck]);

  const keyboardPressCallback = () => {
    if (!withinBounds.current) return;
    setIsKeyboardReady(true);
  };

  const KeyboardImage = () =>
    isKeyboardReady ? <KeyboardReady /> : <Keyboard />;

  if (!startReadinessCheck) {
    return (
      <GridConsumer colSpan={{ default: 10, lg: 6 }}>
        <Title text="Readiness Check" />
        <Paragraph align="left">
          The test needs access to the camera to track any movements during the
          test, but nothing is recorded. The readiness check ensures that the
          camera works and that you are able to give responses without any
          issues.
        </Paragraph>
        <Button
          label="Start readiness check"
          onClick={() => setStartReadinessCheck(true)}
          fullWidth
          stickToBottom
          disabled={false}
        />
      </GridConsumer>
    );
  }

  return (
    <>
      <GridConsumer colSpan={{ default: 10, lg: 6 }}>
        <Title text="Readiness Check" />
        <Paragraph align="left">
          Make sure that your natural seating position is in the center of the
          screen. The camera check will start once you are inside the detection
          zone.
        </Paragraph>
        <canvas className="w-full h-auto" ref={canvasRef} />
        <div className="w-full relative">
          <div className="absolute h-2 w-full bg-[#DDD]"></div>
          <div
            style={{ width: `${progressPercent(time)}%`, transition: "all" }}
            className="absolute h-2 bg-[#45B38B] transition-transform"
          ></div>
        </div>
        <video className="hidden" ref={videoRef} />
        <Paragraph className="mt-8">
          Press the spacebar when inside the detection zone
        </Paragraph>
        <div className="w-[40%] flex justify-center h-32">
          <KeyboardImage />
        </div>
        <KeyboardEventCatcher keyboardPressCallback={keyboardPressCallback} />
      </GridConsumer>
      <GridConsumer>
        <Button
          label="Next"
          fullWidth
          stickToBottom
          onClick={() => navigate(ROUTES.AbilityTestStartPage)}
          disabled={shouldBeDisabled}
        />
      </GridConsumer>
    </>
  );
};

export default React.memo(ReadinessCheckPage);
