import React from "react";

type ButtonProps = {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  variant?: "filled" | "ghost" | "outline";
  size?: "lg";
  fullWidth?: boolean;
  stickToBottom?: boolean;
  tabIndex?: number;
};

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  disabled,
  fullWidth = false,
  variant = "filled",
  stickToBottom = false,
  size = "lg",
  tabIndex,
}: ButtonProps): React.ReactElement => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const getSizeClass = (size: string) => {
    switch (size) {
      default:
        return "h-10 rounded-lg py-2 px-6";
    }
  };

  const getVariantClasses = (variant: string) => {
    switch (variant) {
      case "filled":
        return "text-white font-opensans-semibold font-semibold bg-primary-40 hover:bg-primary-30 active:bg-primary-30 active:outline active:outline-2 active:outline-primary-80 focus:bg-primary-30 focus:outline focus:outline-4 focus:outline-primary-80";
      case "outline":
        return "justify-center text-primary-30 font-opensans-semibold font-semibold border-2 border-primary-40 hover:border-primary-30 active:border-primary-30";
      default:
        return "text-white font-opensans-semibold font-semibold bg-primary-40 hover:bg-primary-30 active:bg-primary-30 active:outline active:outline-2 active:outline-primary-80 focus:bg-primary-30 focus:outline focus:outline-4 focus:outline-primary-80";
    }
  };

  const onClickCatcher = () => {
    if (onClick) {
      buttonRef?.current?.blur();
      onClick();
    }
  };

  const sizeClasses = getSizeClass(size);
  const variantClasses = getVariantClasses(variant);
  const buttonClasses = fullWidth
    ? "cursor-pointer w-full text-center"
    : "cursor-pointer w-fit text-center";
  const disabledClasses = "bg-[#DEE3EB] text-[#8C9198] pointer-events-none";
  return (
    <div className={[stickToBottom && "mt-auto", "w-full"].join(" ")}>
      <button
        ref={buttonRef}
        aria-label={label}
        tabIndex={tabIndex || 1}
        className={[
          sizeClasses,
          variantClasses,
          buttonClasses,
          disabled && disabledClasses,
          "mt-4",
        ].join(" ")}
        onClick={!disabled ? onClickCatcher : undefined}
      >
        <span className="">{label}</span>
      </button>
    </div>
  );
};

export default Button;
