import React from "react";
import { Button, CheckboxForm, GridConsumer, Input, Title } from "components";
import { useDataProvider } from "contexts";
import { RACE } from "contexts/indexedDBContext/enums";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const EthnicityPage = () => {
  const { updateTestMetaData } = useDataProvider();
  const navigate = useNavigate();

  const [races, setRaces] = React.useState<RACE[] | string[]>([]);
  const [other, setOther] = React.useState<string>("");

  const proceed = () => {
    const racesCopy = [...races];

    const otherIndex = racesCopy.findIndex((item) => item === RACE.OTHER);
    if (otherIndex > -1) {
      racesCopy.splice(
        racesCopy.findIndex((item) => item === RACE.OTHER),
        1
      );
      racesCopy.push(RACE.OTHER + ":" + other);
    }

    updateTestMetaData({
      races: racesCopy,
    });
    navigate(ROUTES.RoomEnvironmentPage);
  };

  const choices = [
    {
      value: RACE.AMERICAN_INDIAN_OR_ALASKA_NATIVE,
      label: "American Indian or Alaska Native",
    },
    { value: RACE.ASIAN, label: "Asian" },
    { value: RACE.BLACK, label: "Black or African American" },
    {
      value: RACE.NATIVE_HAWAIIAN,
      label: "Native Hawaiian or Other Pacific Islander",
    },
    { value: RACE.WHITE, label: "White" },
    {
      value: RACE.MENA_AND_ARAB,
      label: "Middle Eastern or Arab or North African",
    },
    { value: RACE.OTHER, label: "Other" },
  ];

  return (
    <GridConsumer>
      <Title text="Race" />
      <Title
        size="medium"
        align="left"
        text="How would you describe yourself?"
      />
      <CheckboxForm
        choices={choices}
        groupName="ethnicity"
        containerClasses="flex mb-4 pt-4 items-center cursor-pointer"
        valueCallback={(value) => setRaces(value || [])}
      />
      <>
        {races.includes(RACE.OTHER) && (
          <Input
            label="Please specify"
            value={other}
            setValue={setOther}
            placeholder="Enter other option"
          />
        )}
      </>
      <Button
        label="Next"
        fullWidth
        disabled={races.length === 0}
        onClick={() => proceed()}
        stickToBottom
      />
    </GridConsumer>
  );
};

export default EthnicityPage;
