import * as React from "react";
import Test from "test/Test";
import {
  Button,
  Cam,
  Grid,
  GridConsumer,
  KeyboardEventCatcher,
  Paragraph,
  Title,
} from "components";
import { useDataProvider } from "contexts";
import { createRecordStore } from "test/utils/recordStore";
import { Results } from "utils/cam/types";
import useCheckFPS from "hooks/useCheckFPS";
import { setFullScreen } from "utils/fullscreen";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const AbilityTestPage = () => {
  const store = createRecordStore("AbilityTest");
  const navigate = useNavigate();
  const {
    config,
    saveAbilityTest,
    nAbilityTests,
    images,
    nStimulisToShow,
    isChild,
    debugMode,
    lastAbilityTestResult,
  } = useDataProvider();
  const [testDone, setTestDone] = React.useState<boolean>(false);
  const [testSaved, setTestSaved] = React.useState<boolean>(false);
  const [testResult, setTestResult] = React.useState<any>(undefined);
  const [clickedStart, setClickedStart] = React.useState<boolean>(false);
  const fullscreenRef = React.useRef<HTMLDivElement>(null);
  const fpsCheck = React.useRef(useCheckFPS());

  const {
    getState,
    subscribe,
    unSubscribe,
    startTest,
    recordPressEvent,
    recordStimuliTimingData,
    recordQualityCheckError,
    testFinished,
    resetStoreState,
  } = store;

  const testData = config?.ability[nAbilityTests];

  const testIsDoneCallback = React.useCallback(() => {
    setTestResult(getState());
    setTestDone(true);
  }, [getState]);

  const handleFrameResults = (results: Results) => {
    fpsCheck.current.getAverageFPS(results.fps);
  };

  // Subscribe to a callback when test is done
  React.useEffect(() => {
    subscribe(testIsDoneCallback);
    return () => unSubscribe(testIsDoneCallback);
  }, [subscribe, testIsDoneCallback, unSubscribe]);

  // Get data and save test to dataProvider
  React.useEffect(() => {
    if (testDone && !testSaved) {
      const startTimeStamp = testResult?.testData?.startTimestamp || -1;
      const testEvaluation = testResult?.testData?.testEvaluation;
      const qualityCheckError = testResult?.testData?.qualityCheckError;

      if (startTimeStamp && (qualityCheckError || testEvaluation)) {
        saveAbilityTest({
          testIndex: nAbilityTests,
          startTimestamp: startTimeStamp,
          testEvaluation: testEvaluation,
          qualityCheckError: qualityCheckError || undefined,
        });
        setTestSaved(true);
        resetStoreState();
      }
    }
  }, [
    nAbilityTests,
    resetStoreState,
    saveAbilityTest,
    testDone,
    testResult?.testData?.qualityCheckError,
    testResult?.testData?.startTimestamp,
    testResult?.testData?.testEvaluation,
    testSaved,
  ]);

  const setFullscreenAndStartTest = () => {
    const el = fullscreenRef.current;
    if (!el) return;
    if (!Boolean(document.fullscreenElement)) {
      setFullScreen(el, "hide");
    }
    setClickedStart(true);

    return () => {
      if (Boolean(document.fullscreenElement)) {
        setFullScreen(el, "show");
      }
    };
  };

  React.useEffect(() => {
    if (testSaved) {
      if (lastAbilityTestResult === "SUCCESSFUL") {
        navigate(ROUTES.FullTestPage);
      } else {
        navigate(ROUTES.AbilityTestCompletePage);
      }
      testData && console.log("TEST CALENDAR: ", testData);
    }
  }, [lastAbilityTestResult, navigate, testData, testSaved]);

  if (nAbilityTests >= 3) {
    navigate(ROUTES.AbilityTestCompletePage);
  }

  if (!testData) {
    return (
      <GridConsumer fillScreen>
        <div className="flex flex-col h-full justify-center items-center text-center bg-test-background">
          <Paragraph>
            There is no test data to run the test on. Please try again.
          </Paragraph>
        </div>
      </GridConsumer>
    );
  }

  return (
    <>
      {!clickedStart ? (
        <div className="absolute inset-0 z-10 bg-primary-100">
          <Grid>
            <GridConsumer>
              <Title text="You are ready to start the Ability Test" />
              <Paragraph align="left">
                Now that you've read the instructions, you are ready to start
                the 1 minute Ability Test.
              </Paragraph>
              <Button
                label="Start Ability Test"
                onClick={() => setFullscreenAndStartTest()}
                fullWidth
                stickToBottom
              />
            </GridConsumer>
          </Grid>
        </div>
      ) : null}
      <GridConsumer fillScreen>
        <div
          ref={fullscreenRef}
          className="flex flex-col h-full w-full justify-center items-center text-center bg-test-background cursor-none"
        >
          {testData && clickedStart && (
            <>
              <Cam
                faceTimeoutValue={5}
                faceTimedoutCallback={() =>
                  recordQualityCheckError("FACE_NOT_DETECTED", isChild || false)
                }
                skipCompatibilityCheck
                storeStartSubscribe={(listener) =>
                  subscribe(listener, "testStarted")
                }
                shouldShowVideo={false}
                faceTrackingResultCallback={handleFrameResults}
              />
              <KeyboardEventCatcher
                keyboardTimeoutValue={120}
                storeStartSubscribe={(listener) =>
                  subscribe(listener, "testStarted")
                }
                keyboardPressCallback={recordPressEvent}
              />
              <div className="w-full h-full">
                <Test
                  isChild={isChild || false}
                  showStats={debugMode}
                  nStimulisToShow={nStimulisToShow}
                  testData={testData}
                  images={images}
                  startRecording={startTest}
                  recordStimuliTimingData={recordStimuliTimingData}
                  recordQualityCheckError={() =>
                    recordQualityCheckError(
                      "TEST_INTERRUPTED",
                      isChild || false
                    )
                  }
                  testFinished={testFinished}
                />
              </div>
            </>
          )}
        </div>
      </GridConsumer>
    </>
  );
};

export default AbilityTestPage;
