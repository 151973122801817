interface WebKitHTMLDivElement extends HTMLDivElement {
  webkitRequestFullscreen: (options: FullscreenOptions) => void;
}

export const setFullScreen = (
  element: HTMLDivElement,
  status: "hide" | "show"
) => {
  const options: FullscreenOptions = {
    navigationUI: status,
  };
  if (element.requestFullscreen) {
    element.requestFullscreen(options);
    return;
  }
  if ((element as WebKitHTMLDivElement).webkitRequestFullscreen) {
    const webkitElement = element as WebKitHTMLDivElement;
    webkitElement.webkitRequestFullscreen(options);
  }
};
