import React from "react";
import { ReactElement } from "react";

type CheckboxFormProps = {
  choices: string[] | { value: string; label: string }[];
  groupName: string;
  containerClasses?: string;
  valueCallback: (value: any[] | undefined) => void;
};

const CheckboxForm: React.FC<CheckboxFormProps> = ({
  choices,
  groupName,
  valueCallback,
  containerClasses,
}: CheckboxFormProps): ReactElement => {
  const [value, setValue] = React.useState<string[]>([]);
  const checkedCointainerClasses = "";
  const hoverClasses = "hover:text-black";
  const checkedHoverClasses = "hover:text-black";

  const checkboxClasses =
    "appearance-none mr-2 cursor-pointer w-5 h-5 text-blue-600  border border-neutral-variant-50 rounded ";
  const checkedCheckboxClasses =
    "checked:before:content-['✓'] checked:bg-primary-40 checked:before:p-[2px] checked:before:relative checked:before:-top-[3px] checked:before:text-white checked:border-primary-40 ";

  const onOptionChange = (e: React.FormEvent<HTMLInputElement>) => {
    const oldValue = value || [];
    let newValue = [e.currentTarget.value];
    if (e.currentTarget.checked)
      newValue = [...oldValue, e.currentTarget.value];
    if (!e.currentTarget.checked)
      newValue = oldValue.filter((v) => v !== e.currentTarget.value);
    setValue(newValue);
    valueCallback(newValue);
  };

  return (
    <div className="w-full">
      {choices.map((choice, index) => {
        let label = typeof choice === "string" ? choice : choice.label;
        let choiceValue = typeof choice === "string" ? choice : choice.value;
        return (
          <label
            key={index}
            htmlFor={groupName + "_" + choiceValue}
            className={[
              containerClasses,
              "flex items-center",
              value?.includes(choiceValue) && checkedCointainerClasses,
              value?.includes(choiceValue) ? checkedHoverClasses : hoverClasses,
            ].join(" ")}
          >
            <input
              type="checkbox"
              id={groupName + "_" + choiceValue}
              name={groupName}
              checked={value?.includes(choiceValue)}
              value={choiceValue}
              className={[
                checkboxClasses,
                value?.includes(choiceValue) && checkedCheckboxClasses,
              ].join(" ")}
              onChange={onOptionChange}
            />
            <p>{label}</p>
          </label>
        );
      })}
    </div>
  );
};

export default CheckboxForm;
