import React from "react";
import {
  Alert,
  Button,
  Gap,
  GridConsumer,
  Input,
  Paragraph,
  Title,
} from "components";
import { useDataProvider } from "contexts/dataContext";
import logo from "../../assets/qbtech.png";
import { useIsOnline } from "hooks";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const VoucherInputPage = () => {
  const [voucher, setVoucher] = React.useState<string>("");
  const navigate = useNavigate();
  const { auth, error, loading, clearError } = useDataProvider();
  const { isOnline } = useIsOnline();

  const onProceed = () => {
    clearError();
    if (isOnline) {
      auth({ voucher, onSuccess: () => navigate(ROUTES.LabelPage) });
    }
  };

  return (
    <GridConsumer>
      <div className="text-center">
        <Title text="Welcome" />
        <Paragraph>
          To start the test you need to add a voucher code that you received
          from your clinician.
        </Paragraph>
      </div>
      <Gap size={80} vertical />
      <div className="w-full">
        <Input
          label="What is your voucher code?"
          placeholder="eg JNODYJ"
          value={voucher}
          setValue={setVoucher}
          error={error}
        />
      </div>
      <div>
        {!isOnline && (
          <Alert type="error">
            You seem to be offline, please check your internet connection
          </Alert>
        )}
        {isOnline && error && (
          <Alert type="error">
            Your voucher code is invalid. Make sure you've typed it correctly or
            check with your clinician.
          </Alert>
        )}
      </div>
      <div className="flex justify-center mb-2 mt-auto">
        <img src={logo} className="w-[144px] mt-auto" alt="Qbcheck Logo" />
      </div>
      <Button
        label={loading ? "loading" : "Next"}
        onClick={() => onProceed()}
        disabled={voucher.length <= 0 || loading}
        fullWidth
        stickToBottom
      />
    </GridConsumer>
  );
};

export default React.memo(VoucherInputPage);
