import { ReactElement } from "react";

type ParagraphProps = {
  size?: "small" | "medium" | "large";
  align?: "left" | "center" | "right";
  color?: "black" | "red";
  type?: "text" | "bulletList";
  className?: string;
  children: ReactElement | ReactElement[] | string;
};

const Paragraph: React.FC<ParagraphProps> = ({
  children,
  color = "black",
  size = "medium",
  align = "center",
  type = "text",
  className = "",
}: ParagraphProps): ReactElement => {
  let sizeClass = "text-body-medium";
  if (size === "large") sizeClass = "text-body-large";
  if (size === "small") sizeClass = "text-body-small";

  let alignClass = "text-center";
  if (align === "left") alignClass = "text-left";
  if (align === "right") alignClass = "text-right";

  let colorClass = "text-black";
  if (color === "red") colorClass = "text-error-40";

  const bulletListClasses =
    type === "bulletList"
      ? "ml-8 relative before:absolute before:content-['∙'] before:text-[24px] before:-left-4 before:-top-[1px]"
      : "";

  return (
    <p
      className={[
        className,
        sizeClass,
        alignClass,
        colorClass,
        bulletListClasses,
        type === "bulletList" ? "w-[calc(100%-32px)] mb-4" : "w-full mb-4",
      ].join(" ")}
    >
      {children}
    </p>
  );
};

export default Paragraph;
