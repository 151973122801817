import { GridConsumer, Image, Paragraph, Title } from "components";
import {
  IconClose,
  LabelCE,
  LabelFactory,
  LabelMD,
  LabelUDI,
} from "assets/images";
import { APP_VERSION } from "utils/env";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const IntendedUsePage = () => {
  const navigate = useNavigate();

  return (
    <GridConsumer>
      <div>
        <Title text="Intended use" />
        <button
          data-testid="closeButton"
          className="absolute top-12 right-14 p-2 w-10 text-gray-500 cursor-pointer"
          onClick={() => navigate(ROUTES.LabelPage)}
        >
          <Image src={IconClose} />
        </button>
      </div>
      <div className="tracking-tight">
        <Paragraph align="left">
          QbMT Classic is intended to provide healthcare professionals with
          measurements of hyperactivity, impulsivity, and inattention to aid in
          the assessment of ADHD and in the evaluation of treatment
          interventions in patients with ADHD.
        </Paragraph>
        <Paragraph align="left">
          QbMT Classic is not intended as a standalone diagnostic test but as an
          aid in the clinical diagnosis and treatment process together with
          other clinically relevant information.
        </Paragraph>
      </div>
      <div className="text-left w-full">
        <Title text="QbMT Classic" size="large" align="left" />
        <div className="mb-4">
          <a href="https://www.qbtech.com">www.qbtech.com</a>
        </div>
      </div>
      <div className="relative w-full">
        <div className="w-full mb-4">
          <div className="flex flex-row">
            <div className="flex flex-row w-1/2">
              <div className="w-24 mr-4">
                <Image className="min-w-[64px]" src={LabelFactory} />
              </div>
              <div>
                <p className="font-bold">Qbtech AB</p>
                <p className="whitespace-nowrap">Cardellgatan 1</p>
                <p className="whitespace-nowrap">114 36 Stockholm</p>
              </div>
            </div>
            <div className="flex flex-col w-1/2 items-end">
              <div className="w-1/4 mb-2">
                <Image src={LabelMD} />
              </div>
              <div className="w-1/4 mb-2">
                <Image src={LabelCE} />
                {/*TODO: Replace with correct certificate number*/}
                nnnn
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mb-4">
          <p className="font-bold underline">
            Version and version release date and time
          </p>
          {/*TODO: Set date without hardcoding.*/}
          <p>{APP_VERSION} 20230310080000</p>
        </div>
        <div className="w-full mb-8">
          <div className="flex flex-row w-1/2 items-center">
            <div className="w-16 mr-4">
              <Image src={LabelUDI} />
            </div>
            <div className="w-1/2">
              <p className="align-middle">
                (01)07350103930098(8012)wt230310080000
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full">
          <div className="w-1/2 pr-4">
            <p className="font-bold underline">UK Responsible Person</p>
            <div className="tracking-tight">
              <p>Qbtech Limited </p>
              <p>Hill House 1, Little New Street, London</p>
              <p>EC4A 3TR</p>
              <p>England, United Kingdom</p>
              <p className="font-bold">+44 (0)20 8996 5148</p>
            </div>
          </div>
          <div className="w-1/2 pr-4">
            <p className="font-bold underline">US Agent</p>
            <div className="tracking-tight">
              <p>Qbtech Inc. </p>
              <p>8 Greenway Plaza</p>
              <p>Houston, Texas</p>
              <p>SUITE 750 USA</p>
              <p className="font-bold">+1 844 467 2832</p>
            </div>
          </div>
        </div>
      </div>
    </GridConsumer>
  );
};

export default IntendedUsePage;
