import { ReactElement } from "react";

type GridProps = {
  children: ReactElement | ReactElement[] | undefined;
};

const Grid: React.FC<GridProps> = ({ children }: GridProps): ReactElement => {
  const gridClasses = "w-[calc(100%-64px)] h-full grid grid-cols-12 gap-8 mx-8";
  return <div className={[gridClasses].join(" ")}>{children}</div>;
};

export default Grid;
