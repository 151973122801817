import * as React from "react";
import Test from "test/Test";
import {
  Button,
  Cam,
  Grid,
  GridConsumer,
  KeyboardEventCatcher,
  Paragraph,
  Title,
} from "components";
import { Results } from "utils/cam/types";
import { useDataProvider } from "contexts";
import { useCheckFPS } from "hooks";
import { createRecordStore } from "test/utils/recordStore";
import { setFullScreen } from "utils/fullscreen";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const FullTestPage = () => {
  const store = createRecordStore("FullTest");
  const navigate = useNavigate();
  const {
    config,
    saveFullTest,
    nFullTests,
    nStimulisToShow,
    images,
    debugMode,
    isChild,
    lastFullTestResult,
  } = useDataProvider();
  const [testDone, setTestDone] = React.useState<boolean>(false);
  const [testSaved, setTestSaved] = React.useState<boolean>(false);
  const [testResult, setTestResult] = React.useState<any>(undefined);
  const [clickedStart, setClickedStart] = React.useState<boolean>(false);
  const fullscreenRef = React.useRef<HTMLDivElement>(null);
  const fpsCheck = React.useRef(useCheckFPS());

  const {
    getState,
    subscribe,
    unSubscribe,
    startTest,
    recordPressEvent,
    recordStimuliTimingData,
    recordQualityCheckError,
    recordFaceTrackingEvent,
    testFinished,
    resetStoreState,
  } = store;

  const testData = config?.full[nFullTests];

  const testIsDoneCallback = React.useCallback(() => {
    setTestResult(getState());
    setTestDone(true);
  }, [getState]);

  const handleFrameResults = (results: Results) => {
    fpsCheck.current.getAverageFPS(results.fps);
    recordFaceTrackingEvent(results);
  };

  // Subscribe to a callback when test is done
  React.useEffect(() => {
    subscribe(testIsDoneCallback);
    return () => unSubscribe(testIsDoneCallback);
  }, [subscribe, testIsDoneCallback, unSubscribe]);

  // Get data and save test to dataProvider
  React.useEffect(() => {
    if (testDone && !testSaved && testResult?.testData) {
      const startTimestamp = testResult?.testData?.startTimestamp || -1;

      const { qualityCheckError, clickEventList, stimuliTiming, boxList } =
        testResult?.testData;

      if (startTimestamp) {
        saveFullTest({
          testIndex: nFullTests,
          startTimestamp: startTimestamp,
          qualityCheckError: qualityCheckError || undefined,
          clickEventList,
          stimuliTiming: stimuliTiming,
          boxList: boxList,
        });
        setTestSaved(true);
        resetStoreState();
      }
    }
  }, [
    nFullTests,
    resetStoreState,
    saveFullTest,
    testDone,
    testResult?.testData,
    testSaved,
  ]);

  const setFullscreenAndStartTest = () => {
    const el = fullscreenRef.current;
    if (!el) return;
    if (!Boolean(document.fullscreenElement)) {
      setFullScreen(el, "hide");
    }
    setClickedStart(true);

    return () => {
      if (Boolean(document.fullscreenElement)) {
        setFullScreen(el, "show");
      }
    };
  };

  React.useEffect(() => {
    if (testSaved) {
      if (lastFullTestResult === "SUCCESSFUL") {
        navigate(ROUTES.FullTestCompletedPage);
      } else {
        navigate(ROUTES.FullTestCancelledPage);
      }
      testData && console.log("TEST CALENDAR: ", testData);
    }
  }, [navigate, lastFullTestResult, testData, testSaved]);

  if (nFullTests >= 3) {
    navigate(ROUTES.FullTestCancelledPage);
  }

  if (!testData) {
    return (
      <GridConsumer fillScreen>
        <div className="flex flex-col h-full justify-center items-center text-center bg-test-background">
          <Paragraph>
            There is no test data to run the test on. Please try again.
          </Paragraph>
        </div>
      </GridConsumer>
    );
  }

  return (
    <>
      {!clickedStart ? (
        <div className="absolute inset-0 z-10 bg-primary-100">
          <Grid>
            <GridConsumer>
              <Title text="Ability Test completed" />
              <Paragraph align="left">
                {`
                Great job! You just completed the Ability Test. It's now time to
                start the ${
                  isChild ? "15" : "20"
                } minute test. Make sure that you are sitting
                comfortably and that you are not disturbed for the duration of
                the test.
              `}
              </Paragraph>
              <Button
                label="Start the full test"
                onClick={() => setFullscreenAndStartTest()}
                fullWidth
                stickToBottom
              />
            </GridConsumer>
          </Grid>
        </div>
      ) : null}
      <GridConsumer fillScreen>
        <div
          ref={fullscreenRef}
          className="flex flex-col h-full justify-center items-center text-center bg-test-background"
        >
          {testData && clickedStart && (
            <>
              <Cam
                faceTimeoutValue={75}
                faceTimedoutCallback={() =>
                  recordQualityCheckError("FACE_NOT_DETECTED", isChild || false)
                }
                skipCompatibilityCheck
                storeStartSubscribe={(listener) =>
                  subscribe(listener, "testStarted")
                }
                shouldShowVideo={false}
                faceTrackingResultCallback={handleFrameResults}
              />
              <KeyboardEventCatcher
                keyboardTimeoutValue={120}
                keyboardTimeoutCallback={() =>
                  recordQualityCheckError(
                    "KEYBOARD_PRESS_NOT_DETECTED",
                    isChild || false
                  )
                }
                storeStartSubscribe={(listener) =>
                  subscribe(listener, "testStarted")
                }
                keyboardPressCallback={recordPressEvent}
              />
              <Test
                isChild={isChild || false}
                showStats={debugMode}
                images={images}
                testData={testData}
                nStimulisToShow={nStimulisToShow}
                startRecording={startTest}
                recordStimuliTimingData={recordStimuliTimingData}
                recordQualityCheckError={() =>
                  recordQualityCheckError("TEST_INTERRUPTED", isChild || false)
                }
                testFinished={testFinished}
              />
            </>
          )}
        </div>
      </GridConsumer>
    </>
  );
};

export default FullTestPage;
