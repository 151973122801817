import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { reverseTable, ROUTES } from "utils/routingTable";
import BackButton from "./BackButton";

const MainBackButton = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  function showButton() {
    switch (pathname) {
      case "/":
      case ROUTES.IntendedUsePage:
      case ROUTES.RoomEnvironmentPage:
      case ROUTES.TestInstructionPage:
      case ROUTES.AbilityTestCompletePage:
      case ROUTES.FullTestPage:
      case ROUTES.FullTestCompletedPage:
      case ROUTES.FullTestCancelledPage:
        return false;
      default:
        return true;
    }
  }

  function doNavBackwards() {
    const destination = reverseTable[pathname];
    if (destination) {
      navigate(destination);
    } else {
      console.warn(`Reverse destination ${destination} not found!`);
    }
  }

  return <BackButton showButton={showButton()} onClick={doNavBackwards} />;
};

export default MainBackButton;
