import { useCountDown } from "hooks";
import React from "react";

type KeyboardEventCatcherProps = {
  keyboardTimeoutValue?: number;
  keyboardTimeoutCallback?: () => void;
  keyboardPressCallback: () => void;
  storeStartSubscribe?: (listener: () => void) => void;
};

const reallyLongTime = 1000000;

const KeyboardEventCatcher = ({
  keyboardTimeoutValue,
  keyboardTimeoutCallback,
  keyboardPressCallback,
  storeStartSubscribe,
}: KeyboardEventCatcherProps) => {
  const [time, setTime] = React.useState<number>(
    keyboardTimeoutValue || reallyLongTime
  );
  console.log("Koca: time ", time);

  const { startTimer, resetTimer } = useCountDown({
    duration: keyboardTimeoutValue || reallyLongTime,
    stopOnReset: false,
    callback: (time) => {
      setTime(time);
    },
  });

  React.useEffect(() => {
    if (!keyboardTimeoutCallback) return;
    if (time <= 0) {
      keyboardTimeoutCallback();
    }
  }, [keyboardTimeoutCallback, time]);

  React.useEffect(() => {
    const handlePress = ({ code, repeat }: KeyboardEvent): void => {
      switch (code) {
        case "Space":
          if (repeat) return;
          resetTimer();
          keyboardPressCallback();
          break;

        default:
          break;
      }
    };

    storeStartSubscribe && storeStartSubscribe(startTimer);

    document.addEventListener("keydown", handlePress);
    return () => {
      document.removeEventListener("keydown", handlePress);
    };
  }, [keyboardPressCallback, resetTimer, startTimer, storeStartSubscribe]);

  return null;
};

export default KeyboardEventCatcher;
