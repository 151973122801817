import React from "react";
import { ReactElement } from "react";

type GridColProps = 4 | 6 | 8 | 10 | 12;
type GridConsumerProps = {
  children: ReactElement | ReactElement[] | undefined;
  colSpan?: { default: GridColProps; lg: GridColProps };
  className?: string;
  fillScreen?: boolean;
};

const fourCols = "col-span-4 col-start-6";
const sixCols = "col-span-6 col-start-4";
const eightCols = "col-span-8 col-start-3";
const tenCols = "col-span-10 col-start-2";
const twelveCols = "col-span-12 col-start-1";

const fourColsLg = "lg:col-span-4 lg:col-start-5";
const sixColsLg = "lg:col-span-6 lg:col-start-4";
const eightColsLg = "lg:col-span-8 lg:col-start-3";
const tenColsLg = "lg:col-span-10 lg:col-start-2";
const twelveColsLg = "lg:col-span-12 lg:col-start-1";

const getColsLg = (size: number) => {
  switch (size) {
    case 4:
      return fourColsLg;
    case 6:
      return sixColsLg;
    case 8:
      return eightColsLg;
    case 10:
      return tenColsLg;
    case 12:
      return twelveColsLg;
  }
};

const getCols = (size: number) => {
  switch (size) {
    case 4:
      return fourCols;
    case 6:
      return sixCols;
    case 8:
      return eightCols;
    case 10:
      return tenCols;
    case 12:
      return twelveCols;
  }
};

const GridConsumer: React.FC<GridConsumerProps> = ({
  children,
  colSpan = { default: 12, lg: 4 },
  className,
  fillScreen,
}: GridConsumerProps): ReactElement => {
  const gridConsumerClasses =
    "items-center first:pt-16 flex flex-col last:pb-16";
  const gridClasses = [
    gridConsumerClasses,
    className,
    getColsLg(colSpan.lg),
    getCols(colSpan.default),
  ].join(" ");

  return (
    <div
      className={[
        fillScreen ? "absolute w-screen h-screen inset-0" : gridClasses,
        className,
      ].join(" ")}
    >
      {children}
    </div>
  );
};

export default GridConsumer;
