import React from "react";
import { Button, GridConsumer } from "components";
import { useDataProvider } from "contexts";
import {
  Step0,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
} from "./AdultSteps";
import {
  Step0 as Step0Children,
  Step1 as Step1Children,
  Step2 as Step2Children,
  Step3 as Step3Children,
  Step4 as Step4Children,
  Step5 as Step5Children,
  Step6 as Step6Children,
  Step7 as Step7Children,
  Step8 as Step8Children,
} from "./ChildrenSteps";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";
import { BackButton } from "../../components/BackButton";

const adultScreens = [
  <Step0 />,
  <Step1 />,
  <Step2 />,
  <Step3 />,
  <Step4 />,
  <Step5 />,
  <Step6 />,
  <Step7 />,
  <Step8 />,
];

const childScreens = [
  <Step0Children />,
  <Step1Children />,
  <Step2Children />,
  <Step3Children />,
  <Step4Children />,
  <Step5Children />,
  <Step6Children />,
  <Step7Children />,
  <Step8Children />,
];

const RoomEnvironmentPage = () => {
  const disabled = false;
  const [step, setStep] = React.useState<number>(0);
  const navigate = useNavigate();
  const { isChild } = useDataProvider();
  const screens = isChild ? childScreens : adultScreens;

  const onClick = () => {
    if (screens.length === step + 1) {
      navigate(ROUTES.TestInstructionPage);
    }
    setStep((prev) => prev + 1);
    window.scrollTo(0, 0);
  };

  let buttonText = "Next";
  if (step === 0) buttonText = "Get ready for the test";

  return (
    <GridConsumer>
      <BackButton
        onClick={() =>
          step === 0
            ? navigate(ROUTES.DemographicsOptInOutPage)
            : setStep(step - 1)
        }
      />
      {screens[step]}
      <Button
        label={buttonText}
        onClick={onClick}
        fullWidth
        stickToBottom
        disabled={disabled}
      />
    </GridConsumer>
  );
};

export default RoomEnvironmentPage;
