import { Button, GridConsumer, Image, Paragraph, Title } from "components";
import { TestCancelledIllustration } from "assets/images";
import { useDataProvider } from "contexts";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";
import { BackButton } from "components/BackButton";

const AbilityTestCompletedPage = () => {
  const { lastAbilityTestResult, nAbilityTests } = useDataProvider();
  const navigate = useNavigate();

  const TestCancelledSharedBlock = () => {
    return (
      <>
        <Image src={TestCancelledIllustration} className="mt-6" />
        <div className="mt-auto w-full">
          <Button
            label="Test Instructions"
            onClick={() => navigate(ROUTES.TestInstructionPage)}
            fullWidth
            variant="outline"
          />
          <Button
            label="Restart Ability Test"
            onClick={() => navigate(ROUTES.AbilityTestStartPage)}
            fullWidth
          />
        </div>
      </>
    );
  };

  const Message = () => {
    switch (lastAbilityTestResult) {
      case "SUCCESSFUL":
        return (
          <>
            <Title text="Ability Test completed" />
            <Paragraph align="left">
              Great job! You just completed the Ability Test. It's now time to
              start the 20 minute test. Make sure that you are sitting
              comfortably and that you are not disturbed for the duration of the
              test.
            </Paragraph>
            <Button
              label="Start the full test"
              onClick={() => navigate(ROUTES.FullTestPage)}
              fullWidth
              stickToBottom
            />
          </>
        );
      case "FACE_NOT_DETECTED":
        return (
          <>
            <Title text="We couldn't detect your face" />
            <Paragraph align="left">
              Remember that you can always go back and review the instructions
              again to make sure you understand the test.
            </Paragraph>
            <TestCancelledSharedBlock />
          </>
        );
      case "INCORRECT_RESPONSES":
        return (
          <>
            <Title text="There were too many incorrect responses." />
            <Paragraph align="left">
              Remember that you can always go back and review the instructions
              again to make sure you understand how to perform the test.
            </Paragraph>
            <TestCancelledSharedBlock />
          </>
        );
      case "TEST_INTERRUPTED":
        return (
          <>
            <Title text="Test was interrupted" />
            <Paragraph align="left">
              The test was cancelled in an unexpected way. Please restart the
              Ability Test to proceed.
            </Paragraph>
            <TestCancelledSharedBlock />
          </>
        );
      default:
        return (
          <>
            <Title text="Test was interrupted" />
            <Paragraph align="left">
              The test was cancelled in an unexpected way. Please restart the
              Ability Test to proceed.
            </Paragraph>
            <TestCancelledSharedBlock />
          </>
        );
    }
  };

  if (lastAbilityTestResult !== "SUCCESSFUL" && nAbilityTests >= 3) {
    return (
      <GridConsumer>
        <Title text="You need to restart the test session" />
        <Paragraph align="left">
          You gave too many incorrect responses or there were quality issues in
          3 different attempts to complete the Ability Test. You need to start
          the test session from the beginning. Please read the instructions more
          carefully.
        </Paragraph>
        <Image src={TestCancelledIllustration} className="mt-6" />
        <div className="mt-auto w-full">
          <Button
            label="Restart test session"
            onClick={() => document.location.reload()}
            fullWidth
          />
        </div>
      </GridConsumer>
    );
  }
  return (
    <GridConsumer>
      <BackButton onClick={() => navigate(ROUTES.AbilityTestStartPage)} />
      <Message />
    </GridConsumer>
  );
};

export default AbilityTestCompletedPage;
