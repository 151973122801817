import { CheckMarkGreen } from "assets/svgs";
import { Alert, Button, GridConsumer, Paragraph, Title } from "components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const CompatibilityInfoPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const FailedAlert = () =>
    state?.failed ? (
      <Alert type="error">
        Sorry, the performance test failed and currently your device does not
        meet the technical requirements for the web test.
      </Alert>
    ) : null;

  const FailedButton = () =>
    state?.failed ? (
      <Link to={ROUTES.CompatibilityCheckPage}>
        <Button variant="outline" label="Restart" fullWidth />
      </Link>
    ) : null;

  const DefaultMessage = () => (
    <div>
      <Paragraph align="left" type="bulletList">
        All computers need to have their performance tested before they can run
        a test.
      </Paragraph>
      <Paragraph align="left" type="bulletList">
        The test will ensure that you have a working web camera and enough
        computer performance to run the test. It takes 15 seconds to complete.
      </Paragraph>
    </div>
  );

  const SuccessMessage = () => (
    <div className="flex flex-col">
      <Paragraph align="left" type="bulletList">
        Great! Your computer meets the requirements to perform the test.
      </Paragraph>
      <div className="mt-8 mx-auto">
        <CheckMarkGreen size={56} />
      </div>
    </div>
  );

  return (
    <GridConsumer>
      <Title text="Performance & compatibility" />
      {state?.successful ? <SuccessMessage /> : <DefaultMessage />}
      <div className="w-full mt-auto">
        <FailedAlert />
        <FailedButton />
        <Button
          disabled={state?.failed}
          label="Next"
          onClick={() =>
            state?.successful
              ? navigate(ROUTES.SexAndBirthPage)
              : navigate(ROUTES.CompatibilityCheckPage)
          }
          fullWidth
        />
      </div>
    </GridConsumer>
  );
};

export default CompatibilityInfoPage;
