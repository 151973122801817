function CheckMarkGreen({ size }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? size.toString() : "32"}
      height={size ? size.toString() : "32"}
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#45B38B" rx="16"></rect>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M23.665 10.335a1.145 1.145 0 010 1.618L13.715 22l-5.38-5.47a1.145 1.145 0 010-1.619 1.142 1.142 0 011.616 0l3.763 3.767 8.335-8.343a1.142 1.142 0 011.616 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CheckMarkGreen;
