import React from "react";

type UseCountDownProps = {
  duration: number;
  stopOnReset?: boolean;
  callback: (time: number) => void;
  onReachingZeroCallback?: () => void;
};

type UseCountDownReturnValues = {
  startTimer: () => void;
  stopTimer: () => void;
  resetTimer: () => void;
  timeLeft: number;
  isCounting: boolean;
};

const useCountDown: ({
  duration,
}: UseCountDownProps) => UseCountDownReturnValues = ({
  duration,
  stopOnReset = false,
  callback,
  onReachingZeroCallback,
}) => {
  const [timeLeft, setTimeLeft] = React.useState(duration);
  const [isCounting, setIsCounting] = React.useState(false);
  const intervalRef = React.useRef<undefined | NodeJS.Timer>(undefined);

  const startTimer = React.useCallback(() => {
    if (intervalRef.current !== undefined) return;
    setIsCounting(true);
    intervalRef.current = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft >= 1) return timeLeft - 1;
        return 0;
      });
    }, 1000);
  }, []);

  const stopTimer = React.useCallback(() => {
    if (intervalRef.current === undefined) return;
    setIsCounting(false);
    clearInterval(intervalRef.current);
    intervalRef.current = undefined;
  }, []);

  const resetTimer = React.useCallback(() => {
    if (stopOnReset) {
      stopTimer();
    }
    setTimeLeft(duration);
  }, [duration, stopOnReset, stopTimer]);

  React.useEffect(() => {
    callback(timeLeft);

    if (timeLeft === 0) {
      onReachingZeroCallback && onReachingZeroCallback();
    }
  }, [callback, onReachingZeroCallback, timeLeft]);

  return { startTimer, stopTimer, resetTimer, timeLeft, isCounting };
};

export default useCountDown;
