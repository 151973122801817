import * as React from "react";
import type { ApiMethods } from "./types";
import { dbSave, dbGet, dbClear, dbRemove, dbUpdate } from "./apiMethods";
import { COLLECTION_NAMES } from "./enums";

type IndexDBProviderProps = { children: React.ReactNode };
const dbMethods: ApiMethods = {
  dbSave,
  dbGet,
  dbUpdate,
  dbRemove,
  dbClear,
};
const IndexDBContext = React.createContext<ApiMethods>(dbMethods);

function IndexDBProvider({ children }: IndexDBProviderProps) {
  const value = { dbSave, dbGet, dbUpdate, dbRemove, dbClear };
  return (
    <IndexDBContext.Provider value={value}>{children}</IndexDBContext.Provider>
  );
}

function useIndexDB() {
  const context = React.useContext(IndexDBContext);
  if (context === undefined) {
    throw new Error("useIndexDB must be used within a IndexDBContext");
  }
  return context;
}

export { IndexDBProvider, useIndexDB, IndexDBContext, COLLECTION_NAMES };
export { IndexDB } from "./indexDB";
