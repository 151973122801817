import type { DBTestDataRecordFile, UpdateData } from "./types";
import type { IndexDB } from "./indexDB";
import { COLLECTION_NAMES } from "./enums";

export async function dbSave(
  db: IndexDB,
  collectionName: COLLECTION_NAMES,
  data: DBTestDataRecordFile,
  reference: string
) {
  if (data) {
    db[collectionName].add(data, reference);
  }
}

export async function dbGet(
  db: IndexDB,
  collectionName: COLLECTION_NAMES,
  reference: string
) {
  const data = db[collectionName].where("reference").equals(reference);
  return data;
}

export async function dbGetAll(db: IndexDB, collectionName: COLLECTION_NAMES) {
  const data = db[collectionName];
  return data;
}

export async function dbUpdate(
  db: IndexDB,
  collectionName: COLLECTION_NAMES,
  data: UpdateData,
  reference: string
) {
  const updatedData = db[collectionName]
    .where("reference")
    .equals(reference)
    .modify(data);
  return updatedData;
}

export async function dbRemove(
  db: IndexDB,
  collectionName: COLLECTION_NAMES,
  reference: string
) {
  const primaryKeys = await db[collectionName]
    .where("reference")
    .equals(reference)
    .primaryKeys();
  db[collectionName].bulkDelete(primaryKeys);
}

export async function dbClear(db: IndexDB, collectionName: COLLECTION_NAMES) {
  db[collectionName].clear();
}
