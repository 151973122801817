import React, { useEffect, useRef } from "react";
import { startCamera, stopCamera } from "utils/cam";
import { Results } from "utils/cam/types";

import { useCheckFPS, useCountDown } from "hooks";
import useCompatibilityCheck from "hooks/useCompatibilityCheck";
import { GridConsumer, Paragraph, Title } from "components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const DURATION = 15;

const CompatibilityCheckPage = () => {
  const navigate = useNavigate();
  const canvasRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const fpsCheck = useRef(useCheckFPS());

  const [isFpsAcceptable, setIsFpsAcceptable] = React.useState<boolean>(false);
  const [time, setTime] = React.useState(DURATION);
  const [isCameraAccessGranted, setIsCameraAccessGranted] =
    React.useState(false);

  const compatibilityCheck = useCompatibilityCheck();

  const onResultsCallback = React.useCallback((results: Results) => {
    fpsCheck.current.getAverageFPS(results.fps);
    setIsFpsAcceptable(fpsCheck.current.isFPSAcceptable.current);
  }, []);

  const { startTimer, stopTimer } = useCountDown({
    duration: DURATION,
    stopOnReset: false,
    callback: (counter) => {
      if (time !== counter) {
        setTime(counter);
      }
    },
  });

  const startCam = React.useCallback(() => {
    if (canvasRef.current && videoRef.current)
      startCamera({
        canvasElementReact: canvasRef.current,
        videoElementReact: videoRef.current,
        onResultsCallback: onResultsCallback,
      });
  }, [onResultsCallback]);

  useEffect(() => {
    if (time <= 0) {
      stopTimer();
      if (isFpsAcceptable && compatibilityCheck.isCompatible) {
        navigate(ROUTES.CompatibilityInfoPage, { state: { successful: true } });
      } else {
        navigate(ROUTES.CompatibilityInfoPage, { state: { failed: true } });
      }
    }
  }, [
    time,
    stopTimer,
    isFpsAcceptable,
    compatibilityCheck.isCompatible,
    navigate,
  ]);

  useEffect(() => {
    startCam();
    return () => {
      stopCamera();
    };
  }, [startCam]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isCameraAccessGranted) {
        clearInterval(interval);
      }
      checkCameraAccess();
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [isCameraAccessGranted]);

  useEffect(() => {
    if (isCameraAccessGranted) {
      startTimer();
    }
  }, [isCameraAccessGranted, startTimer]);

  const checkCameraAccess = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    for (const device of devices) {
      if (device.kind === "videoinput" && device.label !== "") {
        setIsCameraAccessGranted(true);
        console.log("CAMERA ACCESS GRANTED");
      }
    }
  };

  const statusMessage = () => {
    if (!isCameraAccessGranted)
      return "Allow camera access to start camera check";
    if (time > 0) return "Performance check ongoing";
    return "";
  };

  const FpsTooLowMessage = () =>
    !isFpsAcceptable && time === 0 ? (
      <Paragraph color="red">
        Your device's camera FPS is too low. This might be due to insufficient
        hardware capacity.
      </Paragraph>
    ) : null;

  const NotCompatibleMessage = () =>
    !compatibilityCheck.isCompatible ? (
      <Paragraph color="red">
        Your device is not compatible with this application. Please use a fully
        updated version of your browser.
      </Paragraph>
    ) : null;

  return (
    <>
      <GridConsumer colSpan={{ default: 10, lg: 6 }}>
        <Title text="Performance & compatibility" />
        <Paragraph align="left">
          We will now check if your webcam and device meet the technical
          requirements for the test. During this computer check your face will
          be tracked. However, no images or videos will be created during or
          after the check.
        </Paragraph>
        <div className="w-full bg-neutral-variant-90 h-96 flex items-center">
          <div className="w-full self-auto text-center items-center">
            <div className="text-title-large">{statusMessage()}</div>
            <Paragraph className="mt-4 text-title-large">
              {time.toString()}
            </Paragraph>
          </div>
        </div>
        <div className="w-[40%] flex justify-center"></div>
        <canvas className="hidden" ref={canvasRef} />
        <video className="hidden" ref={videoRef} />
      </GridConsumer>
      <GridConsumer>
        <FpsTooLowMessage />
        <NotCompatibleMessage />
      </GridConsumer>
    </>
  );
};

export default React.memo(CompatibilityCheckPage);
