import { Button, GridConsumer, Paragraph, Title } from "components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const LabelPage = () => {
  const disabled = false;
  const navigate = useNavigate();

  return (
    <GridConsumer>
      <Title text="Test session is active" />
      <div className="mb-4">
        <Paragraph align="left">
          You will now perform a test that helps healthcare professionals in the
          assessment of ADHD. It consists of a few steps:
        </Paragraph>
        <Paragraph align="left" type="bulletList">
          Some questions about you
        </Paragraph>
        <Paragraph align="left" type="bulletList">
          How to set up your test environment
        </Paragraph>
        <Paragraph align="left" type="bulletList">
          A test of your device
        </Paragraph>
        <Paragraph align="left" type="bulletList">
          A short ability test
        </Paragraph>
        <Paragraph align="left" type="bulletList">
          The full test
        </Paragraph>
      </div>
      <div className="w-full">
        <button
          className="text-primary-40 text-body-large font-black"
          onClick={() => navigate(ROUTES.IntendedUsePage)}
        >
          Product information
        </button>
      </div>
      <Button
        label="Next"
        fullWidth
        stickToBottom
        disabled={disabled}
        onClick={() => navigate(ROUTES.CompatibilityInfoPage)}
      />
    </GridConsumer>
  );
};

export default LabelPage;
