import React from "react";
import { Button, GridConsumer, RadioForm, Title } from "components";
import { useDataProvider } from "contexts";
import { ETHNICITY } from "contexts/indexedDBContext/enums";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const IsHispanicPage = () => {
  const navigate = useNavigate();
  const { updateTestMetaData } = useDataProvider();

  const [isHispanic, setIsHispanic] = React.useState<boolean | null>(null);

  const proceed = () => {
    if (isHispanic) {
      updateTestMetaData({ ethnicity: ETHNICITY.HISPANIC });
    } else {
      updateTestMetaData({ ethnicity: ETHNICITY.NOT_HISPANIC });
    }
    navigate(ROUTES.EthnicityPage);
  };

  return (
    <GridConsumer>
      <Title text="Ethnicity" />
      <Title
        size="medium"
        align="left"
        text="Are you of Hispanic, Latino or Spanish origin?"
      />
      <RadioForm
        choices={["No", "Yes"]}
        groupName="isHispanic"
        valueCallback={(value) =>
          value === "Yes" ? setIsHispanic(true) : setIsHispanic(false)
        }
      />
      <Button
        label="Next"
        fullWidth
        disabled={isHispanic === null}
        onClick={() => proceed()}
        stickToBottom
      />
    </GridConsumer>
  );
};

export default IsHispanicPage;
