import {
  TEST_COMPLETED_CASES,
  Box,
  StimuliTiming,
} from "contexts/indexedDBContext/types";
import { getlocalIsoDateTime } from "utils";
import { Results } from "utils/cam/types";
import { evaluateClickData } from "./evaluateClickData";

type TestType = "AbilityTest" | "FullTest";

const getDefaultState = () => {
  return {
    recording: false,
    recordStartTimestamp: 0,
    startTimestamp: "",
    clickEventList: [] as number[],
    stimuliTiming: [] as StimuliTiming[],
    qualityCheckError: undefined as TEST_COMPLETED_CASES | undefined,
    boxList: [] as Box[],
    testEvaluation: {
      passed: false,
      omissionError: 0,
      commissionError: 0,
    },
  };
};

export function recordStore() {}

export const createRecordStore = (testType: TestType) => {
  let state = {
    testData: getDefaultState(),
    testType: testType,
  };

  let listeners = new Set<() => void>();
  let startListeners = new Set<() => void>();

  const subscribe = (listener: () => void, fn?: "testStarted" | undefined) => {
    if (fn === "testStarted") {
      startListeners.add(listener);
    } else {
      listeners.add(listener);
    }
  };

  const unSubscribe = (
    listener: () => void,
    fn?: "testStarted" | undefined
  ) => {
    if (fn === "testStarted") {
      startListeners.delete(listener);
    } else {
      listeners.delete(listener);
    }
  };

  const _dispatch = () => {
    listeners.forEach((listener) => listener());
  };

  const _startDispatch = () => {
    startListeners.forEach((listener) => listener());
  };

  const getState = () => {
    return state;
  };

  const resetStoreState = () => {
    state.testData = getDefaultState();
  };

  const startTest = () => {
    const now = new Date();
    state.testData.startTimestamp = getlocalIsoDateTime(now.getTime());
    state.testData.recordStartTimestamp = now.getTime();
    state.testData.recording = true;
    _startDispatch();
  };

  const testFinished = (isChild: boolean) => {
    state.testData.recording = false;

    const result = evaluateClickData({
      clickData: state.testData.clickEventList || [],
      stimuliData: state.testData.stimuliTiming || [],
      isChild,
    });

    const { nOmissionErrorPercentage, nCommissionErrorPercentage, passed } =
      result;

    state.testData.testEvaluation = {
      passed: passed,
      omissionError: nOmissionErrorPercentage,
      commissionError: nCommissionErrorPercentage,
    };

    console.log("Test DATA:", state.testData);

    _dispatch();
  };

  const recordQualityCheckError = (
    reason: TEST_COMPLETED_CASES,
    isChild: boolean
  ) => {
    state.testData.qualityCheckError = reason;
    state.testData.recording = false;
    testFinished(isChild);
  };

  const recordPressEvent = () => {
    if (state.testData.recording === false) return;

    let clickEventList: number[] = [];
    if (state.testData.clickEventList) {
      clickEventList = state.testData.clickEventList;
    }
    clickEventList.push(
      new Date().getTime() - state.testData.recordStartTimestamp
    );
    state.testData.clickEventList = clickEventList;
  };

  const recordFaceTrackingEvent = (result: Results) => {
    if (state.testData.recording === false) return;
    if (!result?.detections[0]?.boundingBox) return;

    let boxList: Box[] = [];
    if (state.testData.boxList) {
      boxList = state.testData.boxList;
    }

    const { xCenter, yCenter, width, height } =
      result.detections[0].boundingBox;

    boxList.push({
      score: 0,
      rect: {
        x: xCenter,
        y: yCenter,
        w: width,
        h: height,
      },
      timestamp: new Date().getTime() - state.testData.recordStartTimestamp,
    });

    state.testData.boxList = boxList;
  };

  const recordStimuliTimingData = ({
    stimuliId,
    expectedDisplayTime,
    timestamp,
  }: {
    stimuliId: number;
    expectedDisplayTime: number;
    timestamp: number;
  }) => {
    if (state.testData.recording === false) return;

    let stimuliTiming: StimuliTiming[] = [];
    if (state.testData.stimuliTiming) {
      stimuliTiming = state.testData.stimuliTiming;
    }
    stimuliTiming.push({
      stimuliId: stimuliId,
      expectedDisplayTime: expectedDisplayTime,
      timestamp: timestamp - state.testData.recordStartTimestamp,
    });
    state.testData.stimuliTiming = stimuliTiming;
  };

  window.getState = getState;

  return {
    getState,
    subscribe,
    unSubscribe,
    startTest,
    testFinished,
    recordPressEvent,
    recordStimuliTimingData,
    recordQualityCheckError,
    recordFaceTrackingEvent,
    resetStoreState,
  };
};

declare const window: any;
