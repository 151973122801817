import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import {
    Alert,
    Button,
    CheckboxForm,
    Gap,
    GridConsumer,
    Paragraph,
    RadioForm,
    Title,
} from "components";
import { TextField } from "@mui/material";

import moment, { Moment } from "moment";
import { useDataProvider } from "contexts/dataContext";
import { useIsOnline } from "hooks";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/routingTable";

const SexAndBirthPage = () => {
  const navigate = useNavigate();
  const { getConfig, loading, jwtData, uploadIndexDBData } = useDataProvider();
  const { isOnline } = useIsOnline();
  const [sex, setSex] = React.useState<string | undefined>();
  const [dateMessage, setDateMessage] = React.useState<string | undefined>();
  const [sexMessage, setSexMessage] = React.useState<string | undefined>();
  const [date, setDate] = React.useState<Moment | null | undefined>();
  const [informationCheckBox, setInformationCheckBox] =
    React.useState<boolean>(false);

  const matchingDate = () => {
    if (!jwtData?.dob || !date) return;
    let date1 = new Date(jwtData.dob);
    let date2 = new Date(date?.valueOf());

    const sameYear = date1.getFullYear() === date2.getFullYear();
    const sameMonth = date1.getMonth() === date2.getMonth();
    const sameDay = date1.getDay() === date2.getDay();

    if (sameYear && sameMonth && sameDay) {
      return true;
    }
    return false;
  };

  const matchingSex = () => {
    if (sex?.charAt(0) !== jwtData?.sex) return false;
    return true;
  };

  const resetMessages = () => {
    setDateMessage(undefined);
    setSexMessage(undefined);
  };

  const onConfigFail = () => {
    setDateMessage(
      "We can not validate your sex and date of birth at the moment, please try again later or contact your clinician"
    );
  };

  const isUserInValidDateRange = (date: Moment) => {
    const now = moment();
    const age = now.diff(date, "years");

    if (age < 6) {
      setDateMessage("The test is not valid for a test user younger than 6");
      return false;
    } else if (age > 59) {
      setDateMessage("The test is not valid for a test user older than 59");
      return false;
    }
    return true;
  };

  const onProceed = () => {
    if (!sex || !date) return;
    let hasError = false;
    resetMessages();
    isUserInValidDateRange(date);
    if (!matchingDate()) {
      setDateMessage("The date doesn't match the voucher");
      hasError = true;
    }
    if (!matchingSex()) {
      setSexMessage("The selected sex doesn't match the voucher");
      hasError = true;
    }
    if (!hasError) {
      if (isUserInValidDateRange(date)) {
        getConfig({
          data: {
            sex: sex?.charAt(0),
            dateOfBirth: date.format("YYYY-MM-DD"),
          },
          onSuccess: () => {
            uploadIndexDBData().then(() => navigate(ROUTES.MedicationPage));
          },
          onFail: onConfigFail,
        });
      }
    }
  };

  return (
    <GridConsumer>
      <Title text="Personal information" />
      <Title size="medium" text="What is your sex?" align="left" />
      <Alert>
        Your sex is used to compare your test results with a similar
        demographic. It can be found on your birth certificate.
      </Alert>
      <RadioForm
        choices={["Female", "Male"]}
        className={sexMessage ? "" : "mb-4"}
        groupName="gender"
        valueCallback={(value) => setSex(value)}
      />
      <Paragraph className={sexMessage && "mb-4 mt-2"} color="red" align="left">
        {sexMessage ? JSON.stringify(sexMessage) : ""}
      </Paragraph>
      <Gap size={40} />
      <Title size="medium" text="When were you born?" align="left" />
      <DatePicker
        openTo="year"
        orientation="portrait"
        inputFormat="yyyy-MM-DD"
        views={["year", "month", "day"]}
        label="Date"
        className="w-full border-transparent focus:border-transparent focus:ring-0"
        value={date}
        onChange={(newValue) => {
          setDate(newValue);
        }}
        renderInput={(params) => <TextField {...params} helperText={null} />}
      />
      <Paragraph color="red" align="left" className="mt-2">
        {dateMessage ? JSON.stringify(dateMessage) : ""}
      </Paragraph>
      <>
        {!isOnline && (
          <Alert type="error">
            You seem to be offline, please check your internet connection
          </Alert>
        )}
      </>
      <div className="mt-auto flex items-center  w-full">
        <div className="flex items-center mb-1">
          <CheckboxForm
            choices={["The information I provide is accurate"]}
            groupName="accurateCheck"
            valueCallback={(value) => {
              if (value && value.length > 0) {
                setInformationCheckBox(true);
              } else {
                setInformationCheckBox(false);
              }
            }}
          />
        </div>
      </div>
      <Button
        label={loading ? "Loading" : "Next"}
        fullWidth
        disabled={!sex || !date?.isValid() || loading || !informationCheckBox}
        onClick={() => onProceed()}
      />
    </GridConsumer>
  );
};

export default SexAndBirthPage;
